* {
  font-family: "Freeman", sans-serif;
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Freeman&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Freeman&family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap');

.onDiv {
  color: gold;
}

.img2:hover {
  cursor: pointer;
}

@media screen and (max-width: 499px) {
  .landD {
    display: none;
  }

  .carouselContainerLands {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: space-between;
  }

  .imgsMainDiv {
    display: none;
  }

  .allwrapLands {
    background: url('./assets/bg-mob.png') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    height: 100vh;
    /* Set height to 100% of the viewport height */
  }

  .landsSection {
    margin-top: 110px;
  }

  .landImg {
    width: 300px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    animation: pop2 0.7s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
  }

  .miniBarMain {
    padding-bottom: 5px;
    padding-top: 5px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    display: flex;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10000;
    background: rgb(0, 0, 0);
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.7315301120448179) 0%, rgba(0, 0, 0, 0.5942752100840336) 50%, rgba(0, 0, 0, 0.28335084033613445) 100%);
  }

  .miniBar {
    display: flex;
    justify-content: space-between;
  }

  .navbarMain {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
  }

  .navbar2 {
    width: 30px;
    justify-content: flex-end;
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
  }

  .navbar {
    width: 30px;
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
  }

  #fontSize {
    font-size: 16px;
    color: white;
    text-align: center;
    text-shadow: 0 0 10px #FFFFFF;
    transition: 0.2s ease-in-out;
  }

  #fontSize:hover {
    color: #4ba345;
  }

  .littleNav div {
    text-align: center;
    padding: 10px;
    cursor: pointer;
  }

  .littleNav {
    background-color: #1d1d1d;
    width: 100%;
    flex-flow: column wrap;
    display: flex;
    border-bottom: 5px #4ba345 solid;
    transition: opacity 0.5s ease-in-out;
    /* Transition for fade effect */
    animation: slideRight 0.5s forwards;
    /* Initial slide-in animation */
    padding-bottom: 30px;
    padding-top: 10px;
    z-index: 10000000;
    position: fixed;
    top: 55px;
  }

  @keyframes slideRight {
    0% {
      transform: translateX(100%);
    }

    100% {
      transform: translateX(0);
    }
  }

  .hidden {
    display: none;
  }

  .close {
    width: 85px;
    height: 85px;
    justify-content: flex-end;
    margin-left: auto;
    cursor: pointer;
    transition: transform .3s;
  }

  .navLink {
    color: #ff000091;
  }

  .littleNav img:hover {
    transform: scale(1.1);
  }

  .logoLoad {
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-bottom: 2%;
    animation: zoomInOut 4s infinite;
  }

  @keyframes zoomInOut {
    0% {
      transform: scale(1);
    }

    50% {
      transform: scale(1.1);
      /* Zoom in */
    }

    100% {
      transform: scale(1);
      /* Zoom out */
    }
  }

  .page-loader2 {
    width: 100%;
    height: 100vh;
    background: rgb(52, 52, 52);
    background: radial-gradient(circle, rgba(52, 52, 52, 1) 0%, rgba(22, 22, 22, 1) 50%, rgba(0, 0, 0, 1) 100%);
    position: absolute;
    z-index: 10000000;
    position: fixed;
    opacity: 0.5px;
  }

  .parent-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    flex-flow: column nowrap;
    /* Adjust the height based on your layout */
  }

  @keyframes l5 {

    0%,
    11% {
      background-position: 35% 35%
    }

    14%,
    36% {
      background-position: 65% 35%
    }

    38%,
    61% {
      background-position: 65% 65%
    }

    64%,
    86% {
      background-position: 35% 65%
    }

    88%,
    100% {
      background-position: 35% 35%
    }
  }

  /* src/App.css */
  .fade-container {
    background-color: gold;
    animation: fadeOut 2s forwards;
    /* Make sure the container covers the whole page */
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .fade-out {
    animation: fadeOut 2s forwards;
  }

  @keyframes fadeOut {
    from {
      opacity: 1;
    }

    to {
      opacity: 0;
      background-color: transparent;
    }
  }

  h1 {
    color: white;
    font-size: 2rem;
  }

  .light {
    height: 100vh;
    position: relative;
  }

  ::-webkit-scrollbar {
    display: none;
  }

  .mainBg {
    height: 100vh;
    /* Full screen height */
    width: 100vw;
    /* Full screen width */
    position: absolute;
    /* Absolute positioning */
    top: 0;
    /* Aligns top to the top of the viewport */
    left: 0;
    /* Aligns left to the left of the viewport */
    object-fit: cover;
    /* Ensures the image covers the entire area without stretching */
    object-position: center;
    /* Centers the image */
  }

  .button {
    position: absolute;
    z-index: 1;
    margin-left: 140px;
    margin-top: 350px;
    background-color: rgba(160, 121, 74, 0);
    width: 50%;
    height: 35%;
    cursor: pointer;
  }


  .light2 {
    display: flex;
    justify-content: space-between;
    width: 40vw;
    margin-left: auto;
    margin-right: auto;
    background-color: aquamarine;

    width: 180px;
    height: 600px;
    overflow-y: scroll;
  }

  .land1 {
    width: 50%;
    height: 50%;
    width: 140px;
    height: 140px;
    margin: 10px;

    background-color: #a8a8df;
  }

  @keyframes float {

    0%,
    100% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-20px);
    }
  }

  .land2 {
    width: 50%;
    height: 50%;
  }

  .land3 {
    width: 50%;
    height: 50%;
  }

  .images3 {
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
  }

  .images3 img {
    width: 45%;
    margin-right: 5%;
    margin-left: 5%;
    position: relative;
  }

  .imgsMainDiv {
    position: relative;
    width: 100%;
    /* Ensure the container takes up the full width */
    height: 100vh;
    /* Ensure the container takes up the full viewport height */
    overflow-x: auto;
    overflow-y: hidden;
    /* Optionally hide vertical scrollbar */
    background-color: #519c00;
  }

  .bgImgs {
    position: absolute;
    top: 0;
    left: 0;
    width: 230%;
    /* Full viewport width */
    height: auto;
    /* Maintain aspect ratio */
  }

  .img1 {
    width: 31%;
    position: absolute;
    z-index: 5;
   margin-top: 9.8%;
    margin-left: 22%;
    animation: floating 8s ease-in-out infinite;
  }

  .img2 {
    width: 55%;
    position: absolute;
    z-index: 5;
    margin-left: 163%;
    margin-top: 1%;
    animation: floating 8.2s ease-in-out infinite;
  }

  .img3 {
    width: 62%;
    position: absolute;
    z-index: 5;
    margin-left: 84%;
    margin-top: 4%;
    animation: floating 8s ease-in-out infinite;
  }

  @keyframes floating {
    0% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-10px);
    }

    100% {
      transform: translateY(0);
    }
  }

  .element1 {
    width: 8.8%;
    position: absolute;
    z-index: 84;
    margin-left: 6.5%;
    animation: floating 5s ease-in-out infinite;
  }

  .element2 {
    width: 6%;
    position: absolute;
    z-index: 84;
    margin-left: 62.5%;
    margin-top: 2%;
    animation: floating 10s ease-in-out infinite;
  }

  .element3 {
    width: 15%;
    position: absolute;
    z-index: 84;
    margin-left: 57%;
    margin-top: 10%;
    animation: floating 12s ease-in-out infinite;
  }

  .element4 {
    width: 6.5%;
    position: absolute;
    z-index: 84;
    margin-left: 80%;
    margin-top: 20%;
    animation: floating 13s ease-in-out infinite;
  }

  .element5 {
    width: 13%;
    position: absolute;
    z-index: 84;
    margin-left: 135%;
    margin-top: 18.2%;
    animation: floating 7s ease-in-out infinite;
  }

  .element6 {
    width: 15.5%;
    position: absolute;
    z-index: 4;
    margin-left: 166.8%;
    margin-top: 13.5%;
    animation: floating 7s ease-in-out infinite;
  }

  .element7 {
    width: 11%;
    position: absolute;
    z-index: 4;
    margin-left: 205.5%;
    margin-top: 5.5%;
    animation: floating 7s ease-in-out infinite;
  }

  .element8 {
    width: 5.5%;
    position: absolute;
    z-index: 100;
    margin-left: 210%;
    margin-top: 15.2%;
    animation: floating 10s ease-in-out infinite;
    cursor: pointer;
  }

  .element9 {
    width: 6.5%;
    position: absolute;
    z-index: 4;
    margin-left: 55.2%;
    margin-top: 32.5%;
    animation: floating 10s ease-in-out infinite;
  }

  .element10 {
    width: 6.5%;
    position: absolute;
    z-index: 5;
    margin-left: 46.5%;
    margin-top: 33.5%;
    animation: rotate-left-to-right 1s ease-in-out infinite alternate;
  }

  .element11 {
    width: 5%;
    position: absolute;
    z-index: 5;
    margin-left: 4%;
    margin-top: 30%;
    animation: rotate-left-to-right 1s ease-in-out infinite alternate;
  }


  @keyframes rotate-left-to-right {

    0% {
      transform: rotateZ(12deg);
    }

    100% {
      transform: rotateZ(8deg);
    }

  }

  /* In your CSS file */
  .goldenLight {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(255, 233, 110);
    opacity: 0;
    animation: emitLight 3s linear forwards;
    z-index: 9999;
    /* Ensure the golden light is above other content */
  }

  .page-loader {
    width: 100%;
    height: 100vh;
    background-color: rgb(255, 233, 110);
    position: absolute;
    z-index: 100;
    position: fixed;
    opacity: 0.5px;
  }


  @keyframes emitLight {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  .table {
    height: 100vh;
    /* Full screen height */
    width: 100vw;
    /* Full screen width */
    position: absolute;
    /* Absolute positioning */
    top: 0;
    /* Aligns top to the top of the viewport */
    left: 0;
    /* Aligns left to the left of the viewport */
    object-fit: cover;
    /* Ensures the image covers the entire area without stretching */
    object-position: center;
    /* Centers the image */

  }

  .bookElement {
    position: absolute;
    width: 90%;
    margin-left: 8%;
    margin-top: 65%;
    transform: rotate(-7deg);
    /* Initial rotation */
    cursor: pointer;
    transition: all 1s ease;
    /* Adding transition for all properties */
    padding: 0px;
    z-index: 5;
  }

  .bookElement.left {
    margin-left: 20%;
    /* Move to the left */
  }

  .bookElement.up {
    margin-top: 10%;
    /* Move up slightly */
    transform: rotate(0deg);
    /* Rotate to 30deg */
  }

  .bookElement.moving {
    margin-left: calc(33% - 26%);
    /* Adjustment based on the actual size of the book element */
    margin-top: 40%;
    transform: rotate(0deg);
    z-index: 2;
    width: 85%;
    position: fixed;

  }

  .backgroundOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    opacity: 0;
    transition: opacity 0.5s ease;
    z-index: 1;
  }


  .game {
    z-index: 1;
    position: absolute;
    width: 11%;
    margin-left: 40%;
    margin-top: 20%;
    rotate: 18deg;
  }

  /* Styles for the popup */
  .popup {
    transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 100000000000;
    background-color: rgba(0, 0, 0, 0.726);
  }

  .bookOpened {
    width: 85%;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .close {
    position: absolute;
    top: 70px;
    right: 100px;
    cursor: pointer;
    font-size: 25px;
    z-index: 50;
    color: white;
    transition: 0.2s ease-in-out;
  }

  .close:hover {
    color: rgb(156, 156, 156);
  }

  .allwrapAbout {
    background: url('./assets/7639148.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
  }


  .allwrapSocial {
    background-color: rgb(0, 0, 0);
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
  }

  .allwrapAbout2 {
    min-height: 100vh;
    height: max-content;
  }

  /* Initially hide the navDiv elements */
  .headers2 .right .navDiv {
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
  }

  /* Show navDiv elements when hovering over .headers2 or .logo */
  .headers2:hover .right .navDiv {
    opacity: 1;
  }

  .logo:hover~.right .navDiv {
    opacity: 1;
  }

  /* Styling for the logo image */
  .logoPic {
    width: 100px;
    /* Adjust width as needed */
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
  }

  /* Basic styling for .navDiv */
  .navDiv {
    margin-left: 25px;
    color: white;
    font-size: 15px;
    font-weight: 400;
    cursor: pointer;
    transition: color 0.2s ease-in-out;
    /* Add smooth color transition */
  }

  .navDiv:hover {
    color: #c5c4c4;
  }

  .headers {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10000;
    background: rgb(0, 0, 0);
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.7315301120448179) 0%, rgba(0, 0, 0, 0.5942752100840336) 50%, rgba(0, 0, 0, 0.28335084033613445) 100%);
    display: none;
  }


  /* Additional styling for .headers2 */
  .headers2 {
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin: 10px auto;

  }

  .right {
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
  }

  .aboutSection {
    width: 100%;
    margin-top: 150px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .carouselContainer {
    margin-top: 150px;
  }


  .sec1Img {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .sec1Img img {
    width: 300px;
    height: 300px;
    background-color: rgb(188, 247, 207);
    border: 5px solid rgb(81, 253, 136);
    border-radius: 30px;
    rotate: 6deg;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .sec2Img {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .sec2Img img {
    width: 300px;
    height: 300px;
    background-color: rgb(188, 247, 207);
    border: 5px solid rgb(81, 253, 136);
    border-radius: 30px;
    rotate: -6deg;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .sec3Img {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .sec3Img img {
    width: 300px;
    height: 300px;
    background-color: rgb(188, 247, 207);
    border: 5px solid rgb(81, 253, 136);
    border-radius: 30px;
    rotate: 6deg;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .title {
    color: #ffffff;
    font-size: 45px;
    margin-bottom: 1%;
    text-align: center;

  }

  .aboutSection p {
    color: rgb(255, 255, 255);
    font-family: "Nunito", sans-serif;
    text-align: center;

  }

  .eachSections1 {
    display: flex;
    justify-content: space-evenly;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    width: 90%;
    background-color: #3f82cf;
    margin-left: auto;
    margin-right: auto;
    padding: 20px;
    border-radius: 50px;
    rotate: -2deg;
    border: 8px solid white;
    padding: 30px;
    margin-bottom: 70px;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px;
  }

  #t2 {
    text-align: center;
  }

  .eachSections2 {
    display: flex;
    justify-content: space-evenly;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    flex-flow: column nowrap;
    display: flex;
    width: 90%;
    background-color: #ff6f2f;
    margin-left: auto;
    margin-right: auto;
    padding: 20px;
    border-radius: 50px;
    rotate: 2deg;
    border: 8px solid white;
    padding: 30px;
    margin-bottom: 70px;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px;
  }

  .eachSections3 {
    display: flex;
    justify-content: space-evenly;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
    width: 90%;
    background-color: #805eff;
    margin-left: auto;
    margin-right: auto;
    padding: 20px;
    border-radius: 50px;
    rotate: -2deg;
    border: 8px solid white;
    padding: 30px;
    margin-bottom: 70px;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px;

  }

  .eachSections {
    margin-top: 5%;
  }

  .whatsapp {
    animation: floating 4s ease-in-out infinite;
    width: 50px;
    height: 50px;
    z-index: 100;
    cursor: pointer;
    transition: transform .2s;
  }

  .whatsapp:hover {
    transform: scale(1.5);
  }

  .whatsappDiv {
    position: fixed;
    bottom: 30px;
    /* Adjust as needed */
    right: 30px;
    /* Adjust as needed */
    z-index: 10000000;
  }

  .detailsEgg {
    flex: 0 0 100%;
    display: none;
  }

  .detailsEgg.active {
    display: block;
  }

  .arrow {
    cursor: pointer;
    width: 50px;
    height: 50px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .left {
    margin-right: -30px;
    z-index: 5;
  }

  .right-2 {
    margin-left: -30px;
  }

  @keyframes pop2 {
    0% {
      transform: scale(0);
    }

    100% {
      transform: scale(1);
    }
  }


  .detailsEggSub {
    display: flex;
    justify-content: space-between;
    background-color: #275a72;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 20px;
    padding: 20px;
    flex-flow: column nowrap;
    margin-bottom: 50px;
  }

  .detailsEggSub2 {
    display: flex;
    justify-content: space-between;
    background-color: #c0692f;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 20px;
    padding: 20px;
    flex-flow: column nowrap;
    margin-bottom: 50px;

  }

  .detailsEggSub3 {
    display: flex;
    justify-content: space-between;
    background-color: #3f3c50;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 20px;
    padding: 20px;
    flex-flow: column nowrap;
    margin-bottom: 50px;

  }

  .detailsEggSub4 {
    display: flex;
    justify-content: space-between;
    background-color: #cc3a46;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 20px;
    padding: 20px;
    flex-flow: column nowrap;
    margin-bottom: 50px;

  }

  .detailsEggSub5 {
    display: flex;
    justify-content: space-between;
    background-color: #c56300;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 20px;
    padding: 20px;
    flex-flow: column nowrap;
    margin-bottom: 50px;

  }

  .detailsEggSub6 {
    display: flex;
    justify-content: space-between;
    background-color: #39509c;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 20px;
    padding: 20px;
    flex-flow: column nowrap;
    margin-bottom: 50px;

  }

  .detailsEggSub7 {
    display: flex;
    justify-content: space-between;
    background-color: #ce4d3f;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 20px;
    padding: 20px;
    flex-flow: column nowrap;
    margin-bottom: 50px;

  }

  .detailsEggSub8 {
    display: flex;
    justify-content: space-between;
    background-color: #b65269;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 20px;
    padding: 20px;
    flex-flow: column nowrap;
    margin-bottom: 50px;

  }

  .detailsEggSub9 {
    display: flex;
    justify-content: space-between;
    background-color: #37772d;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 20px;
    padding: 20px;
    flex-flow: column nowrap;
    margin-bottom: 50px;

  }

  .detailsEggSub10 {
    display: flex;
    justify-content: space-between;
    background-color: #853394;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 20px;
    padding: 20px;
    flex-flow: column nowrap;
    margin-bottom: 50px;

  }


  .eggMain {
    width: 60%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    animation: pop2 0.7s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
    margin-left: auto;
    margin-right: auto;
  }

  .eggT {
    font-size: 40px;
    color: white;
    margin-bottom: 10px;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    text-align: center;
  }

  .eggP {
    font-family: "Nunito", sans-serif;
    width: 95%;
    color: white;
    background-color: #fbfbfc27;
    border-radius: 7px;
    padding: 20px;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.096), 0px -5px 35px rgba(255, 255, 255, 0.3);
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    margin-left: auto;
    margin-right: auto;
  }

  .contentsEgg {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .carouselContainer {
    display: flex;
  }

  .littleEggsMain {
    display: flex;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 3%;
    display: none;
  }

  .littleEggs {
    width: 200px;
    margin-top: -50px;
    z-index: 10;
  }

  .littleEggs img {
    width: 75px;
    height: 75px;
    display: flex;
    transition: transform .2s;
  }

  .littleEggs img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  .littleEggs div {
    font-size: 14px;
    color: rgb(0, 0, 0);
    text-align: center;
  }

  /*.............. FOOTER ..............*/

  .footer {
    margin-top: 30px;
    margin-left: 5%;
    margin-bottom: 50px;
    margin-top: 120px;
  }

  .footerHeader {
    color: rgb(255, 255, 255);
    font-size: 40px;
  }

  .footerP {
    color: rgb(167, 167, 167);
    font-size: 15px;
    width: 95%;
    margin-top: 20px;
    font-family: "Nunito", sans-serif;
  }

  .line {
    width: 95%;
    height: 1px;
    background-color: rgba(219, 219, 219, 0.24);
    margin-top: 15px;
  }

  .footerBox1 {
    width: 300px;
    height: 100px;
    border-radius: 5px;
    margin-top: 25px;
    background-image: url(./assets/email_before.jpg);
    background-size: cover;
    border: none;
  }

  .footerBox1:hover {
    background-image: url(./assets/email_after.jpg);
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    color: rgb(226, 226, 226);
    cursor: pointer;

  }

  .footerBox2 {
    width: 300px;
    height: 100px;
    border-radius: 5px;
    margin-top: 25px;
    background-image: url(./assets/instagram_before.jpg);
    background-size: cover;
    border: none;
  }

  .footerBox2:hover {
    background-image: url(./assets/instagram_after.jpg);
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    color: rgb(226, 226, 226);
    cursor: pointer;

  }

  .footerBox3 {
    width: 300px;
    height: 100px;
    border-radius: 5px;
    margin-top: 25px;
    background-image: url(./assets/twitter_before.jpg);
    background-size: cover;
    border: none;
  }

  .footerBox3:hover {
    background-image: url(./assets/twitter_after.jpg);
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    color: rgb(226, 226, 226);
    cursor: pointer;

  }

  .footerBox4 {
    width: 300px;
    height: 100px;
    border-radius: 5px;
    margin-top: 25px;
    background-image: url(./assets/discord_before.jpg);
    background-size: cover;
    border: none;
  }

  .footerBox4:hover {
    background-image: url(./assets/discord_after.jpg);
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    color: rgb(226, 226, 226);
    cursor: pointer;

  }

  .iconsSection1 {
    display: flex;
    flex-flow: column nowrap;
  }

  .iconsSection2 {
    display: flex;
    flex-flow: column nowrap;
  }

  .footerDetails {
    color: black;
    font-size: 18px;
    font-weight: bolder;
    width: 360px;
    height: 100px;
    text-align: center;
    padding-top: 30px;
  }

  .footerDetails2 {
    color: rgb(75, 75, 75);
    font-size: 12px;

  }

  .footerDetails:hover {
    color: rgb(226, 226, 226);
  }

  .footerDetails p {

    font-size: 14px;
    color: rgb(161, 161, 161);
    margin-top: -3px;
    font-family: "Nunito", sans-serif;

  }

  .footerDetails2:hover {
    color: rgb(201, 201, 201);
  }

  .elementSocial {
    animation: floating 4s ease-in-out infinite;
    width: 28%;
    z-index: 100;
    cursor: pointer;
    transition: transform .2s;
  }

  .elementSocial {
    position: fixed;
    bottom: 8%;
    /* Adjust as needed */
    right: 8%;
    /* Adjust as needed */
    z-index: 10000000;
    animation: rotate-left-to-right 1s ease-in-out infinite alternate;
  }
}

@media screen and (min-width: 500px) and (max-width: 767px) {
  .landsSection {
    margin-top: 110px;
  }

  .landD {
    display: none;
  }

  .landImg {
    width: 490px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    animation: pop2 0.7s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
  }

  .allwrapLands {
    background: url('./assets/bg-mob.png') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    height: 100vh;
    /* Set height to 100% of the viewport height */
  }

  #landMain {
    display: none;
  }

  .landsSection {
    margin-top: 110px;
  }

  .landImg {
    width: 490px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    animation: pop2 0.7s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
    margin-left: auto;
    margin-right: auto;
  }

  .allwrapLands {
    background: url('./assets/bg-mob.png') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    height: 100vh;
    /* Set height to 100% of the viewport height */
  }

  #landMain {
    display: none;
  }

  .miniBarMain {
    padding-bottom: 5px;
    padding-top: 5px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    display: flex;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10000;
    background: rgb(0, 0, 0);
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.7315301120448179) 0%, rgba(0, 0, 0, 0.5942752100840336) 50%, rgba(0, 0, 0, 0.28335084033613445) 100%);
  }

  .miniBar {
    display: flex;
    justify-content: space-between;
  }

  .navbarMain {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
  }

  .navbar2 {
    width: 30px;
    justify-content: flex-end;
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
  }

  .navbar {
    width: 30px;
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
  }

  #fontSize {
    font-size: 16px;
    color: white;
    text-align: center;
    text-shadow: 0 0 10px #FFFFFF;
    transition: 0.2s ease-in-out;
  }

  #fontSize:hover {
    color: #4ba345;
  }

  .littleNav div {
    text-align: center;
    padding: 10px;
    cursor: pointer;
  }

  .littleNav {
    background-color: #1d1d1d;
    width: 100%;
    flex-flow: column wrap;
    display: flex;
    border-bottom: 5px #4ba345 solid;
    transition: opacity 0.5s ease-in-out;
    /* Transition for fade effect */
    animation: slideRight 0.5s forwards;
    /* Initial slide-in animation */
    padding-bottom: 30px;
    padding-top: 10px;
    z-index: 10000000;
    position: fixed;
    top: 55px;
  }

  @keyframes slideRight {
    0% {
      transform: translateX(100%);
    }

    100% {
      transform: translateX(0);
    }
  }

  .hidden {
    display: none;
  }

  .close {
    width: 85px;
    height: 85px;
    justify-content: flex-end;
    margin-left: auto;
    cursor: pointer;
    transition: transform .3s;
  }

  .navLink {
    color: #ff000091;
  }

  .littleNav img:hover {
    transform: scale(1.1);
  }

  .logoLoad {
    width: 40%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-bottom: 2%;
    animation: zoomInOut 4s infinite;
  }

  @keyframes zoomInOut {
    0% {
      transform: scale(1);
    }

    50% {
      transform: scale(1.1);
      /* Zoom in */
    }

    100% {
      transform: scale(1);
      /* Zoom out */
    }
  }

  .page-loader2 {
    width: 100%;
    height: 100vh;
    background: rgb(52, 52, 52);
    background: radial-gradient(circle, rgba(52, 52, 52, 1) 0%, rgba(22, 22, 22, 1) 50%, rgba(0, 0, 0, 1) 100%);
    position: absolute;
    z-index: 10000000;
    position: fixed;
    opacity: 0.5px;
  }

  .parent-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    flex-flow: column nowrap;
    /* Adjust the height based on your layout */
  }

  @keyframes l5 {

    0%,
    11% {
      background-position: 35% 35%
    }

    14%,
    36% {
      background-position: 65% 35%
    }

    38%,
    61% {
      background-position: 65% 65%
    }

    64%,
    86% {
      background-position: 35% 65%
    }

    88%,
    100% {
      background-position: 35% 35%
    }
  }

  /* src/App.css */
  .fade-container {
    background-color: gold;
    animation: fadeOut 2s forwards;
    /* Make sure the container covers the whole page */
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .fade-out {
    animation: fadeOut 2s forwards;
  }

  @keyframes fadeOut {
    from {
      opacity: 1;
    }

    to {
      opacity: 0;
      background-color: transparent;
    }
  }

  h1 {
    color: white;
    font-size: 2rem;
  }

  .light {
    height: 100vh;
    position: relative;
  }

  ::-webkit-scrollbar {
    display: none;
  }

  .mainBg {
    height: 100vh;
    /* Full screen height */
    width: 100vw;
    /* Full screen width */
    position: absolute;
    /* Absolute positioning */
    top: 0;
    /* Aligns top to the top of the viewport */
    left: 0;
    /* Aligns left to the left of the viewport */
    object-fit: cover;
    /* Ensures the image covers the entire area without stretching */
    object-position: center;
    /* Centers the image */
  }


  .button {
    position: absolute;
    z-index: 1;
    margin-left: 140px;
    margin-top: 210px;
    background-color: rgba(160, 121, 74, 0);
    width: 50%;
    height: 45%;
    cursor: pointer;
  }

  .light2 {
    display: flex;
    justify-content: space-between;
    width: 40vw;
    margin-left: auto;
    margin-right: auto;
    background-color: aquamarine;

    width: 180px;
    height: 600px;
    overflow-y: scroll;
  }

  .land1 {
    width: 50%;
    height: 50%;
    width: 140px;
    height: 140px;
    margin: 10px;

    background-color: #a8a8df;
  }

  @keyframes float {

    0%,
    100% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-20px);
    }
  }

  .land2 {
    width: 50%;
    height: 50%;
  }

  .land3 {
    width: 50%;
    height: 50%;
  }

  .images3 {
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
  }

  .images3 img {
    width: 45%;
    margin-right: 5%;
    margin-left: 5%;
    position: relative;
  }

  .imgsMainDiv {
    position: relative;
    width: 100%;
    /* Ensure the container takes up the full width */
    height: 100vh;
    /* Ensure the container takes up the full viewport height */
    overflow-x: auto;
    overflow-y: hidden;
    /* Optionally hide vertical scrollbar */
    background-color: #519c00;
    display: none;
  }

  .bgImgs {
    position: absolute;
    top: 0;
    left: 0;
    width: 230%;
    /* Full viewport width */
    height: auto;
    /* Maintain aspect ratio */
  }

  .img1 {
    width: 31%;
    position: absolute;
    z-index: 5;
   margin-top: 9.8%;
    margin-left: 22%;
    animation: floating 8s ease-in-out infinite;
  }

  .img2 {
    width: 55%;
    position: absolute;
    z-index: 5;
    margin-left: 163%;
    margin-top: 1%;
    animation: floating 8.2s ease-in-out infinite;
  }

  .img3 {
    width: 62%;
    position: absolute;
    z-index: 5;
    margin-left: 84%;
    margin-top: 4%;
    animation: floating 8s ease-in-out infinite;
  }

  @keyframes floating {
    0% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-10px);
    }

    100% {
      transform: translateY(0);
    }
  }

  .element1 {
    width: 8.8%;
    position: absolute;
    z-index: 84;
    margin-left: 6.5%;
    animation: floating 5s ease-in-out infinite;
  }

  .element2 {
    width: 6%;
    position: absolute;
    z-index: 84;
    margin-left: 62.5%;
    margin-top: 2%;
    animation: floating 10s ease-in-out infinite;
  }

  .element3 {
    width: 15%;
    position: absolute;
    z-index: 84;
    margin-left: 57%;
    margin-top: 10%;
    animation: floating 12s ease-in-out infinite;
  }

  .element4 {
    width: 6.5%;
    position: absolute;
    z-index: 84;
    margin-left: 80%;
    margin-top: 20%;
    animation: floating 13s ease-in-out infinite;
  }

  .element5 {
    width: 13%;
    position: absolute;
    z-index: 84;
    margin-left: 135%;
    margin-top: 18.2%;
    animation: floating 7s ease-in-out infinite;
  }

  .element6 {
    width: 15.5%;
    position: absolute;
    z-index: 4;
    margin-left: 166.8%;
    margin-top: 13.5%;
    animation: floating 7s ease-in-out infinite;
  }

  .element7 {
    width: 11%;
    position: absolute;
    z-index: 4;
    margin-left: 205.5%;
    margin-top: 5.5%;
    animation: floating 7s ease-in-out infinite;
  }

  .element8 {
    width: 5.5%;
    position: absolute;
    z-index: 100;
    margin-left: 210%;
    margin-top: 15.2%;
    animation: floating 10s ease-in-out infinite;
    cursor: pointer;
  }

  .element9 {
    width: 6.5%;
    position: absolute;
    z-index: 4;
    margin-left: 55.2%;
    margin-top: 32.5%;
    animation: floating 10s ease-in-out infinite;
  }

  .element10 {
    width: 6.5%;
    position: absolute;
    z-index: 5;
    margin-left: 46.5%;
    margin-top: 33.5%;
    animation: rotate-left-to-right 1s ease-in-out infinite alternate;
  }

  .element11 {
    width: 5%;
    position: absolute;
    z-index: 5;
    margin-left: 4%;
    margin-top: 30%;
    animation: rotate-left-to-right 1s ease-in-out infinite alternate;
  }


  @keyframes rotate-left-to-right {

    0% {
      transform: rotateZ(12deg);
    }

    100% {
      transform: rotateZ(8deg);
    }

  }

  /* In your CSS file */
  .goldenLight {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(255, 233, 110);
    opacity: 0;
    animation: emitLight 3s linear forwards;
    z-index: 9999;
    /* Ensure the golden light is above other content */
  }

  .page-loader {
    width: 100%;
    height: 100vh;
    background-color: rgb(255, 233, 110);
    position: absolute;
    z-index: 100;
    position: fixed;
    opacity: 0.5px;
  }


  @keyframes emitLight {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }


  .tableMain {
    overflow: auto;
    /* Allows for scrollbars inside the container */
    height: 85vh;
    /* Set a fixed height slightly less than fullscreen to enable scrolling */
  }

  .table {
    height: 100%;
    /* Fill the available space within the container */
    width: 100%;
    position: relative;
    /* Use relative instead of absolute so the parent determines size */
    object-fit: contain;
    /* Ensure aspect ratio matches the initial containing block box */
    object-position: center;
  }

  .bookElement {
    position: fixed;
    width: 60%;
    margin-left: 8%;
    margin-top: 45%;
    transform: rotate(-7deg);
    /* Initial rotation */
    cursor: pointer;
    transition: all 1s ease;
    /* Adding transition for all properties */
    padding: 0px;
    z-index: 5;
  }

  .bookElement.left {
    margin-left: 20%;
    /* Move to the left */
    position: fixed;

  }

  .bookElement.up {
    margin-top: 10%;
    /* Move up slightly */
    transform: rotate(0deg);
    /* Rotate to 30deg */
    position: fixed;

  }

  .bookElement.moving {
    margin-left: calc(33% - 26%);
    /* Adjustment based on the actual size of the book element */
    margin-top: 40%;
    transform: rotate(0deg);
    z-index: 2;
    width: 85%;
    position: fixed;
    position: fixed;

  }

  .backgroundOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    opacity: 0;
    transition: opacity 0.5s ease;
    z-index: 1;
  }


  .game {
    z-index: 1;
    position: absolute;
    width: 11%;
    margin-left: 40%;
    margin-top: 20%;
    rotate: 18deg;
  }

  /* Styles for the popup */
  .popup {
    transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 100000000000;
    background-color: rgba(0, 0, 0, 0.726);
  }

  .bookOpened {
    width: 85%;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .close {
    position: absolute;
    top: 70px;
    right: -15px;
    cursor: pointer;
    font-size: 25px;
    z-index: 50;
    color: white;
    transition: 0.2s ease-in-out;
  }

  .close:hover {
    color: rgb(156, 156, 156);
  }

  .allwrapAbout {
    background: url('./assets/7639148.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
  }


  .allwrapSocial {
    background-color: rgb(0, 0, 0);
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
  }

  .allwrapAbout2 {
    min-height: 100vh;
    height: max-content;
  }

  /* Initially hide the navDiv elements */
  .headers2 .right .navDiv {
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
  }

  /* Show navDiv elements when hovering over .headers2 or .logo */
  .headers2:hover .right .navDiv {
    opacity: 1;
  }

  .logo:hover~.right .navDiv {
    opacity: 1;
  }

  /* Styling for the logo image */
  .logoPic {
    width: 100px;
    /* Adjust width as needed */
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
  }

  /* Basic styling for .navDiv */
  .navDiv {
    margin-left: 25px;
    color: white;
    font-size: 15px;
    font-weight: 400;
    cursor: pointer;
    transition: color 0.2s ease-in-out;
    /* Add smooth color transition */
  }

  .navDiv:hover {
    color: #c5c4c4;
  }

  .headers {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10000;
    background: rgb(0, 0, 0);
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.7315301120448179) 0%, rgba(0, 0, 0, 0.5942752100840336) 50%, rgba(0, 0, 0, 0.28335084033613445) 100%);
    display: none;
  }


  /* Additional styling for .headers2 */
  .headers2 {
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin: 10px auto;

  }

  .right {
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
  }

  .aboutSection {
    width: 100%;
    margin-top: 150px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .carouselContainer {
    margin-top: 150px;
  }


  .sec1Img {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .sec1Img img {
    width: 300px;
    height: 300px;
    background-color: rgb(188, 247, 207);
    border: 5px solid rgb(81, 253, 136);
    border-radius: 30px;
    rotate: 6deg;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .sec2Img {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .sec2Img img {
    width: 300px;
    height: 300px;
    background-color: rgb(188, 247, 207);
    border: 5px solid rgb(81, 253, 136);
    border-radius: 30px;
    rotate: -6deg;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .sec3Img {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .sec3Img img {
    width: 300px;
    height: 300px;
    background-color: rgb(188, 247, 207);
    border: 5px solid rgb(81, 253, 136);
    border-radius: 30px;
    rotate: 6deg;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .title {
    color: #ffffff;
    font-size: 45px;
    margin-bottom: 1%;
    text-align: center;

  }

  .aboutSection p {
    color: rgb(255, 255, 255);
    font-family: "Nunito", sans-serif;
    text-align: center;

  }

  .eachSections1 {
    display: flex;
    justify-content: space-evenly;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    width: 90%;
    background-color: #3f82cf;
    margin-left: auto;
    margin-right: auto;
    padding: 20px;
    border-radius: 50px;
    rotate: -2deg;
    border: 8px solid white;
    padding: 30px;
    margin-bottom: 70px;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px;
  }

  #t2 {
    text-align: center;
  }

  .eachSections2 {
    display: flex;
    justify-content: space-evenly;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    flex-flow: column nowrap;
    display: flex;
    width: 90%;
    background-color: #ff6f2f;
    margin-left: auto;
    margin-right: auto;
    padding: 20px;
    border-radius: 50px;
    rotate: 2deg;
    border: 8px solid white;
    padding: 30px;
    margin-bottom: 70px;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px;
  }

  .eachSections3 {
    display: flex;
    justify-content: space-evenly;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
    width: 90%;
    background-color: #805eff;
    margin-left: auto;
    margin-right: auto;
    padding: 20px;
    border-radius: 50px;
    rotate: -2deg;
    border: 8px solid white;
    padding: 30px;
    margin-bottom: 70px;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px;

  }

  .eachSections {
    margin-top: 5%;
  }

  .whatsapp {
    animation: floating 4s ease-in-out infinite;
    width: 50px;
    height: 50px;
    z-index: 100;
    cursor: pointer;
    transition: transform .2s;
  }

  .whatsapp:hover {
    transform: scale(1.5);
  }

  .whatsappDiv {
    position: fixed;
    bottom: 30px;
    /* Adjust as needed */
    right: 30px;
    /* Adjust as needed */
    z-index: 10000000;
  }

  .detailsEgg {
    flex: 0 0 100%;
    display: none;
  }

  .detailsEgg.active {
    display: block;
  }

  .arrow {
    cursor: pointer;
    width: 50px;
    height: 50px;
    margin-top: auto;
    margin-bottom: auto;
    z-index: 10;
  }

  .left {
    margin-right: -50px;
    z-index: 5;
  }

  .right-2 {
    margin-left: -50px;
  }

  @keyframes pop2 {
    0% {
      transform: scale(0);
    }

    100% {
      transform: scale(1);
    }
  }


  .detailsEggSub {
    display: flex;
    justify-content: space-between;
    background-color: #275a72;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 20px;
    padding: 20px;
    flex-flow: column nowrap;
    margin-bottom: 50px;
  }

  .detailsEggSub2 {
    display: flex;
    justify-content: space-between;
    background-color: #c0692f;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 20px;
    padding: 20px;
    flex-flow: column nowrap;
    margin-bottom: 50px;

  }

  .detailsEggSub3 {
    display: flex;
    justify-content: space-between;
    background-color: #3f3c50;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 20px;
    padding: 20px;
    flex-flow: column nowrap;
    margin-bottom: 50px;

  }

  .detailsEggSub4 {
    display: flex;
    justify-content: space-between;
    background-color: #cc3a46;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 20px;
    padding: 20px;
    flex-flow: column nowrap;
    margin-bottom: 50px;

  }

  .detailsEggSub5 {
    display: flex;
    justify-content: space-between;
    background-color: #c56300;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 20px;
    padding: 20px;
    flex-flow: column nowrap;
    margin-bottom: 50px;

  }

  .detailsEggSub6 {
    display: flex;
    justify-content: space-between;
    background-color: #39509c;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 20px;
    padding: 20px;
    flex-flow: column nowrap;
    margin-bottom: 50px;

  }

  .detailsEggSub7 {
    display: flex;
    justify-content: space-between;
    background-color: #ce4d3f;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 20px;
    padding: 20px;
    flex-flow: column nowrap;
    margin-bottom: 50px;

  }

  .detailsEggSub8 {
    display: flex;
    justify-content: space-between;
    background-color: #b65269;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 20px;
    padding: 20px;
    flex-flow: column nowrap;
    margin-bottom: 50px;

  }

  .detailsEggSub9 {
    display: flex;
    justify-content: space-between;
    background-color: #37772d;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 20px;
    padding: 20px;
    flex-flow: column nowrap;
    margin-bottom: 50px;

  }

  .detailsEggSub10 {
    display: flex;
    justify-content: space-between;
    background-color: #853394;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 20px;
    padding: 20px;
    flex-flow: column nowrap;
    margin-bottom: 50px;

  }


  .eggMain {
    width: 60%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    animation: pop2 0.7s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
    margin-left: auto;
    margin-right: auto;
  }

  .eggT {
    font-size: 40px;
    color: white;
    margin-bottom: 10px;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    text-align: center;
  }

  .eggP {
    font-family: "Nunito", sans-serif;
    width: 95%;
    color: white;
    background-color: #fbfbfc27;
    border-radius: 7px;
    padding: 20px;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.096), 0px -5px 35px rgba(255, 255, 255, 0.3);
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    margin-left: auto;
    margin-right: auto;
  }

  .contentsEgg {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .carouselContainer {
    display: flex;
  }

  .carouselContainerLands {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: space-between;
  }


  .littleEggsMain {
    display: flex;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 3%;
    display: none;
  }

  .littleEggs {
    width: 200px;
    margin-top: -50px;
    z-index: 10;
  }

  .littleEggs img {
    width: 75px;
    height: 75px;
    display: flex;
    transition: transform .2s;
  }

  .littleEggs img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  .littleEggs div {
    font-size: 14px;
    color: rgb(0, 0, 0);
    text-align: center;
  }

  /*.............. FOOTER ..............*/

  .footer {
    margin-top: 30px;
    margin-left: 5%;
    margin-bottom: 50px;
    margin-top: 120px;
  }

  .footerHeader {
    color: rgb(255, 255, 255);
    font-size: 40px;
  }

  .footerP {
    color: rgb(167, 167, 167);
    font-size: 15px;
    width: 95%;
    margin-top: 20px;
    font-family: "Nunito", sans-serif;
  }

  .line {
    width: 95%;
    height: 1px;
    background-color: rgba(219, 219, 219, 0.24);
    margin-top: 15px;
  }

  .footerBox1 {
    width: 300px;
    height: 100px;
    border-radius: 5px;
    margin-top: 25px;
    background-image: url(./assets/email_before.jpg);
    background-size: cover;
    border: none;
  }

  .footerBox1:hover {
    background-image: url(./assets/email_after.jpg);
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    color: rgb(226, 226, 226);
    cursor: pointer;

  }

  .footerBox2 {
    width: 300px;
    height: 100px;
    border-radius: 5px;
    margin-top: 25px;
    background-image: url(./assets/instagram_before.jpg);
    background-size: cover;
    border: none;
  }

  .footerBox2:hover {
    background-image: url(./assets/instagram_after.jpg);
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    color: rgb(226, 226, 226);
    cursor: pointer;

  }

  .footerBox3 {
    width: 300px;
    height: 100px;
    border-radius: 5px;
    margin-top: 25px;
    background-image: url(./assets/twitter_before.jpg);
    background-size: cover;
    border: none;
  }

  .footerBox3:hover {
    background-image: url(./assets/twitter_after.jpg);
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    color: rgb(226, 226, 226);
    cursor: pointer;

  }

  .footerBox4 {
    width: 300px;
    height: 100px;
    border-radius: 5px;
    margin-top: 25px;
    background-image: url(./assets/discord_before.jpg);
    background-size: cover;
    border: none;
  }

  .footerBox4:hover {
    background-image: url(./assets/discord_after.jpg);
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    color: rgb(226, 226, 226);
    cursor: pointer;

  }

  .iconsSection1 {
    display: flex;
    flex-flow: column nowrap;
  }

  .iconsSection2 {
    display: flex;
    flex-flow: column nowrap;
  }

  .footerDetails {
    color: black;
    font-size: 18px;
    font-weight: bolder;
    width: 360px;
    height: 100px;
    text-align: center;
    padding-top: 30px;
  }

  .footerDetails2 {
    color: rgb(75, 75, 75);
    font-size: 12px;

  }

  .footerDetails:hover {
    color: rgb(226, 226, 226);
  }

  .footerDetails p {

    font-size: 14px;
    color: rgb(161, 161, 161);
    margin-top: -3px;
    font-family: "Nunito", sans-serif;

  }

  .footerDetails2:hover {
    color: rgb(201, 201, 201);
  }

  .elementSocial {
    animation: floating 4s ease-in-out infinite;
    width: 28%;
    z-index: 100;
    cursor: pointer;
    transition: transform .2s;
  }

  .elementSocial {
    position: fixed;
    bottom: 8%;
    /* Adjust as needed */
    right: 8%;
    /* Adjust as needed */
    z-index: 10000000;
    animation: rotate-left-to-right 1s ease-in-out infinite alternate;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .miniBarMain {
    display: none;
  }
 
  iframe {
    width: 100%;
    height: 100%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    /* Optional: shadow for a nicer appearance */
    margin-top: 135px;

  }

  .iframeDiv {
    margin: 0;
    padding: 0;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #3f82cf;
  }

  .sky {
    width: 100%;
     background-color: #3f82cf;
  }
  .landD {
    position: absolute;
    z-index: 100;
    margin-top: 46.5%;
    width: 90%;
    text-align: center;
    margin-left: 5%;
    color: white;
    position: fixed;
    top: 0;
  }

  .landH {
    color: gold;
    font-size: 25px;
  }

  .landCon {
    font-family: "Nunito", sans-serif;
    color: white;
    text-shadow: 0 0 10px #ffffff8a;
  }

  .allwrapLands {
    display: none;
  }

  .logoLoad {
    width: 18%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-bottom: 2%;
    animation: zoomInOut 4s infinite;

  }

  @keyframes zoomInOut {
    0% {
      transform: scale(1);
    }

    50% {
      transform: scale(1.1);
      /* Zoom in */
    }

    100% {
      transform: scale(1);
      /* Zoom out */
    }
  }

  .page-loader2 {
    width: 100%;
    height: 100vh;
    background: rgb(52, 52, 52);
    background: radial-gradient(circle, rgba(52, 52, 52, 1) 0%, rgba(22, 22, 22, 1) 50%, rgba(0, 0, 0, 1) 100%);
    position: absolute;
    z-index: 10000000;
    position: fixed;
    opacity: 0.5px;
  }

  .parent-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    flex-flow: column nowrap;
    /* Adjust the height based on your layout */
  }

  @keyframes l5 {

    0%,
    11% {
      background-position: 35% 35%
    }

    14%,
    36% {
      background-position: 65% 35%
    }

    38%,
    61% {
      background-position: 65% 65%
    }

    64%,
    86% {
      background-position: 35% 65%
    }

    88%,
    100% {
      background-position: 35% 35%
    }
  }

  /* src/App.css */
  .fade-container {
    background-color: gold;
    animation: fadeOut 2s forwards;
    /* Make sure the container covers the whole page */
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .fade-out {
    animation: fadeOut 2s forwards;
  }

  @keyframes fadeOut {
    from {
      opacity: 1;
    }

    to {
      opacity: 0;
      background-color: transparent;
    }
  }

  h1 {
    color: white;
    font-size: 2rem;
  }

  .light {
    height: 100vh;
    position: relative;
  }

  ::-webkit-scrollbar {
    display: none;
  }

  .mainBg {
    height: 100vh;
    /* Full screen height */
    width: 100vw;
    /* Full screen width */
    position: absolute;
    /* Absolute positioning */
    top: 0;
    /* Aligns top to the top of the viewport */
    left: 0;
    /* Aligns left to the left of the viewport */
    object-fit: cover;
    /* Ensures the image covers the entire area without stretching */
    object-position: center;
    /* Centers the image */
  }



  .button {
    position: absolute;
    z-index: 1;
    margin-left: 140px;
    margin-top: 210px;
    background-color: rgba(160, 121, 74, 0);
    width: 50%;
    height: 45%;
    cursor: pointer;
  }


  .light2 {
    display: flex;
    justify-content: space-between;
    width: 40vw;
    margin-left: auto;
    margin-right: auto;
    background-color: aquamarine;

    width: 180px;
    height: 600px;
    overflow-y: scroll;
  }

  .land1 {
    width: 50%;
    height: 50%;
    width: 140px;
    height: 140px;
    margin: 10px;

    background-color: #a8a8df;
  }

  @keyframes float {

    0%,
    100% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-20px);
    }
  }

  .land2 {
    width: 50%;
    height: 50%;
  }

  .land3 {
    width: 50%;
    height: 50%;
  }

  .images3 {
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
  }

  .images3 img {
    width: 45%;
    margin-right: 5%;
    margin-left: 5%;
    position: relative;
  }

  .imgsMainDiv {
    position: relative;
    width: 100%;
    /* Ensure the container takes up the full width */
    height: 100vh;
    /* Ensure the container takes up the full viewport height */
    overflow-x: auto;
    overflow-y: hidden;
    /* Optionally hide vertical scrollbar */
    background: rgb(81, 156, 0);
    background: linear-gradient(180deg, rgba(81, 156, 0, 1) 0%, rgba(81, 156, 0, 1) 50%, rgba(13, 24, 0, 1) 100%);

  }

  .bgImgs {
    position: absolute;
    top: 0;
    left: 0;
    width: 230%;
    /* Full viewport width */
    height: auto;
    /* Maintain aspect ratio */
  }

  .img1 {
    width: 31%;
    position: absolute;
    z-index: 5;
   margin-top: 9.8%;
    margin-left: 22%;
    animation: floating 8s ease-in-out infinite;
  }

  .img2 {
    width: 55%;
    position: absolute;
    z-index: 5;
    margin-left: 163%;
    margin-top: 1%;
    animation: floating 8.2s ease-in-out infinite;
  }

  .img3 {
    width: 62%;
    position: absolute;
    z-index: 5;
    margin-left: 84%;
    margin-top: 4%;
    animation: floating 8s ease-in-out infinite;
  }

  @keyframes floating {
    0% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-10px);
    }

    100% {
      transform: translateY(0);
    }
  }

  .element1 {
    width: 8.8%;
    position: absolute;
    z-index: 84;
    margin-left: 6.5%;
    animation: floating 5s ease-in-out infinite;
  }

  .element2 {
    width: 6%;
    position: absolute;
    z-index: 84;
    margin-left: 62.5%;
    margin-top: 2%;
    animation: floating 10s ease-in-out infinite;
  }

  .element3 {
    width: 15%;
    position: absolute;
    z-index: 84;
    margin-left: 57%;
    margin-top: 10%;
    animation: floating 12s ease-in-out infinite;
  }

  .element4 {
    width: 6.5%;
    position: absolute;
    z-index: 84;
    margin-left: 80%;
    margin-top: 20%;
    animation: floating 13s ease-in-out infinite;
  }

  .element5 {
    width: 13%;
    position: absolute;
    z-index: 84;
    margin-left: 135%;
    margin-top: 18.2%;
    animation: floating 7s ease-in-out infinite;
  }

  .element6 {
    width: 15.5%;
    position: absolute;
    z-index: 4;
    margin-left: 166.8%;
    margin-top: 13.5%;
    animation: floating 7s ease-in-out infinite;
  }

  .element7 {
    width: 11%;
    position: absolute;
    z-index: 4;
    margin-left: 205.5%;
    margin-top: 5.5%;
    animation: floating 7s ease-in-out infinite;
  }

  .element8 {
    width: 5.5%;
    position: absolute;
    z-index: 100;
    margin-left: 210%;
    margin-top: 15.2%;
    animation: floating 10s ease-in-out infinite;
    cursor: pointer;
  }

  .element9 {
    width: 6.5%;
    position: absolute;
    z-index: 4;
    margin-left: 55.2%;
    margin-top: 32.5%;
    animation: floating 10s ease-in-out infinite;
  }

  .element10 {
    width: 6.5%;
    position: absolute;
    z-index: 5;
    margin-left: 46.5%;
    margin-top: 33.5%;
    animation: rotate-left-to-right 1s ease-in-out infinite alternate;
  }

  .element11 {
    width: 5%;
    position: absolute;
    z-index: 5;
    margin-left: 4%;
    margin-top: 30%;
    animation: rotate-left-to-right 1s ease-in-out infinite alternate;
  }


  @keyframes rotate-left-to-right {

    0% {
      transform: rotateZ(12deg);
    }

    100% {
      transform: rotateZ(8deg);
    }

  }

  /* In your CSS file */
  .goldenLight {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(255, 233, 110);
    opacity: 0;
    animation: emitLight 3s linear forwards;
    z-index: 9999;
    /* Ensure the golden light is above other content */
  }

  .page-loader {
    width: 100%;
    height: 100vh;
    background-color: rgb(255, 233, 110);
    position: absolute;
    z-index: 100;
    position: fixed;
    opacity: 0.5px;
  }


  @keyframes emitLight {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  .table {
    width: 100%;
    display: block;
    /* Ensure it's displayed as a block element */
    margin: 0;
    /* Remove default margin */
  }

  .bookElement {
    position: absolute;
    width: 52%;
    margin-left: 48%;
    margin-top: 25.5%;
    transform: rotate(-7deg);
    /* Initial rotation */
    cursor: pointer;
    transition: all 1s ease;
    /* Adding transition for all properties */
    padding: 0px;
    z-index: 5;
  }

  .bookElement.left {
    margin-left: 20%;
    /* Move to the left */
  }

  .bookElement.up {
    margin-top: 10%;
    /* Move up slightly */
    transform: rotate(0deg);
    /* Rotate to 30deg */
  }

  .bookElement.moving {
    margin-left: calc(42% - 26%);
    /* Adjustment based on the actual size of the book element */
    margin-top: 12%;
    transform: rotate(0deg);
    z-index: 2;
    width: 67%;
    position: fixed;

  }

  .backgroundOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    opacity: 0;
    transition: opacity 0.5s ease;
    z-index: 1;
  }


  .game {
    z-index: 1;
    position: absolute;
    width: 11%;
    margin-left: 40%;
    margin-top: 20%;
    rotate: 18deg;
  }

  /* Styles for the popup */
  .popup {
    transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 100000000000;
    background-color: rgba(0, 0, 0, 0.726);
  }

  .bookOpened {
    width: 85%;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .close {
    position: absolute;
    top: 70px;
    right: 100px;
    cursor: pointer;
    font-size: 25px;
    z-index: 50;
    color: white;
    transition: 0.2s ease-in-out;
  }

  .close:hover {
    color: rgb(156, 156, 156);
  }

  .allwrapAbout {
    background: url('./assets/7639148.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
  }


  .allwrapSocial {
    background-color: rgb(0, 0, 0);
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
  }

  .allwrapAbout2 {
    min-height: 100vh;
    height: max-content;
  }

  /* Initially hide the navDiv elements */
  .headers2 .right .navDiv {
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
  }

  /* Show navDiv elements when hovering over .headers2 or .logo */
  .headers2:hover .right .navDiv {
    opacity: 1;
  }

  .logo:hover~.right .navDiv {
    opacity: 1;
  }

  /* Styling for the logo image */
  .logoPic {
    width: 120px;
    /* Adjust width as needed */
    cursor: pointer;
  }

  /* Basic styling for .navDiv */
  .navDiv {
    margin-left: 25px;
    color: white;
    font-size: 15px;
    font-weight: 400;
    cursor: pointer;
    transition: color 0.2s ease-in-out;
    /* Add smooth color transition */
  }

  .navDiv:hover {
    color: #c5c4c4;
  }

  .headers {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10000;
    background: rgb(0, 0, 0);
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.7315301120448179) 0%, rgba(0, 0, 0, 0.5942752100840336) 50%, rgba(0, 0, 0, 0.28335084033613445) 100%);
  }


  /* Additional styling for .headers2 */
  .headers2 {
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin: 10px auto;

  }

  .right {
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
  }

  .aboutSection {
    width: 100%;
    margin-top: 18%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .carouselContainer {
    margin-top: 18%;
  }


  .sec1Img {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .sec1Img img {
    width: 300px;
    height: 300px;
    background-color: rgb(188, 247, 207);
    border: 5px solid rgb(81, 253, 136);
    border-radius: 30px;
    rotate: 6deg;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .sec2Img {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .sec2Img img {
    width: 300px;
    height: 300px;
    background-color: rgb(188, 247, 207);
    border: 5px solid rgb(81, 253, 136);
    border-radius: 30px;
    rotate: -6deg;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .sec3Img {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .sec3Img img {
    width: 300px;
    height: 300px;
    background-color: rgb(188, 247, 207);
    border: 5px solid rgb(81, 253, 136);
    border-radius: 30px;
    rotate: 6deg;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .title {
    color: #ffffff;
    font-size: 55px;
    margin-bottom: 1%;
  }

  .aboutSection p {
    color: rgb(255, 255, 255);
    font-family: "Nunito", sans-serif;
  }

  .eachSections1 {
    display: flex;
    justify-content: space-evenly;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    width: 90%;
    background-color: #3f82cf;
    margin-left: auto;
    margin-right: auto;
    padding: 20px;
    border-radius: 50px;
    rotate: -2deg;
    border: 8px solid white;
    padding: 30px;
    margin-bottom: 10%;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px;
  }

  #t2 {
    text-align: right;
  }

  .eachSections2 {
    display: flex;
    justify-content: space-evenly;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    width: 90%;
    background-color: #ff6f2f;
    margin-left: auto;
    margin-right: auto;
    padding: 20px;
    border-radius: 50px;
    rotate: 2deg;
    border: 8px solid white;
    padding: 30px;
    margin-bottom: 10%;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px;
  }

  .eachSections3 {
    display: flex;
    justify-content: space-evenly;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    width: 90%;
    background-color: #805eff;
    margin-left: auto;
    margin-right: auto;
    padding: 20px;
    border-radius: 50px;
    rotate: -2deg;
    border: 8px solid white;
    padding: 30px;
    margin-bottom: 10%;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px;

  }

  .eachSections {
    margin-top: 5%;
  }

  .whatsapp {
    animation: floating 4s ease-in-out infinite;
    width: 50px;
    height: 50px;
    z-index: 100;
    cursor: pointer;
    transition: transform .2s;
  }

  .whatsapp:hover {
    transform: scale(1.5);
  }

  .whatsappDiv {
    position: fixed;
    bottom: 30px;
    /* Adjust as needed */
    right: 30px;
    /* Adjust as needed */
    z-index: 10000000;
  }

  .detailsEgg {
    flex: 0 0 100%;
    display: none;
  }

  .detailsEgg.active {
    display: block;
  }

  .arrow {
    cursor: pointer;
    width: 50px;
    height: 50px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .left {
    margin-right: -30px;
    z-index: 5;
  }

  .right-2 {
    margin-left: -30px;
  }

  @keyframes pop2 {
    0% {
      transform: scale(0);
    }

    100% {
      transform: scale(1);
    }
  }


  .detailsEggSub {
    display: flex;
    justify-content: space-between;
    background-color: #275a72;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 20px;
    padding: 20px;
    flex-flow: column nowrap;
    margin-bottom: 50px;
  }

  .detailsEggSub2 {
    display: flex;
    justify-content: space-between;
    background-color: #c0692f;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 20px;
    padding: 20px;
    flex-flow: column nowrap;
    margin-bottom: 50px;

  }

  .detailsEggSub3 {
    display: flex;
    justify-content: space-between;
    background-color: #3f3c50;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 20px;
    padding: 20px;
    flex-flow: column nowrap;
    margin-bottom: 50px;

  }

  .detailsEggSub4 {
    display: flex;
    justify-content: space-between;
    background-color: #cc3a46;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 20px;
    padding: 20px;
    flex-flow: column nowrap;
    margin-bottom: 50px;

  }

  .detailsEggSub5 {
    display: flex;
    justify-content: space-between;
    background-color: #c56300;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 20px;
    padding: 20px;
    flex-flow: column nowrap;
    margin-bottom: 50px;

  }

  .detailsEggSub6 {
    display: flex;
    justify-content: space-between;
    background-color: #39509c;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 20px;
    padding: 20px;
    flex-flow: column nowrap;
    margin-bottom: 50px;

  }

  .detailsEggSub7 {
    display: flex;
    justify-content: space-between;
    background-color: #ce4d3f;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 20px;
    padding: 20px;
    flex-flow: column nowrap;
    margin-bottom: 50px;

  }

  .detailsEggSub8 {
    display: flex;
    justify-content: space-between;
    background-color: #b65269;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 20px;
    padding: 20px;
    flex-flow: column nowrap;
    margin-bottom: 50px;

  }

  .detailsEggSub9 {
    display: flex;
    justify-content: space-between;
    background-color: #37772d;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 20px;
    padding: 20px;
    flex-flow: column nowrap;
    margin-bottom: 50px;

  }

  .detailsEggSub10 {
    display: flex;
    justify-content: space-between;
    background-color: #853394;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 20px;
    padding: 20px;
    flex-flow: column nowrap;
    margin-bottom: 50px;

  }


  .eggMain {
    width: 60%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    animation: pop2 0.7s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
    margin-left: auto;
    margin-right: auto;
  }

  .eggT {
    font-size: 40px;
    color: white;
    margin-bottom: 10px;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    text-align: center;
  }

  .eggP {
    font-family: "Nunito", sans-serif;
    width: 95%;
    color: white;
    background-color: #fbfbfc27;
    border-radius: 7px;
    padding: 20px;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.096), 0px -5px 35px rgba(255, 255, 255, 0.3);
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    margin-left: auto;
    margin-right: auto;
  }

  .contentsEgg {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .carouselContainer {
    display: flex;
  }

  .littleEggsMain {
    display: flex;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 3%;
    display: none;
  }

  .littleEggs {
    width: 200px;
    margin-top: -50px;
    z-index: 10;
  }

  .littleEggs img {
    width: 75px;
    height: 75px;
    display: flex;
    transition: transform .2s;
  }

  .littleEggs img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  .littleEggs div {
    font-size: 14px;
    color: rgb(0, 0, 0);
    text-align: center;
  }

  /*.............. FOOTER ..............*/

  .footer {
    margin-top: 13%;
    margin-left: 5%;
  }

  .footerHeader {
    color: rgb(255, 255, 255);
    font-size: 50px;
  }

  .footerP {
    color: rgb(167, 167, 167);
    font-size: 15px;
    width: 50%;
    margin-top: 20px;
    font-family: "Nunito", sans-serif;
  }

  .line {
    width: 50%;
    height: 1px;
    background-color: rgba(219, 219, 219, 0.24);
    margin-top: 15px;
  }

  .footerBox1 {
    width: 300px;
    height: 100px;
    border-radius: 5px;
    margin-top: 25px;
    background-image: url(./assets/email_before.jpg);
    background-size: cover;
    border: none;
  }

  .footerBox1:hover {
    background-image: url(./assets/email_after.jpg);
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    color: rgb(226, 226, 226);
    cursor: pointer;

  }

  .footerBox2 {
    width: 300px;
    height: 100px;
    border-radius: 5px;
    margin-top: 25px;
    background-image: url(./assets/instagram_before.jpg);
    background-size: cover;
    margin-left: 15px;
    border: none;
  }

  .footerBox2:hover {
    background-image: url(./assets/instagram_after.jpg);
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    color: rgb(226, 226, 226);
    cursor: pointer;

  }

  .footerBox3 {
    width: 300px;
    height: 100px;
    border-radius: 5px;
    margin-top: 25px;
    background-image: url(./assets/twitter_before.jpg);
    background-size: cover;
    border: none;
  }

  .footerBox3:hover {
    background-image: url(./assets/twitter_after.jpg);
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    color: rgb(226, 226, 226);
    cursor: pointer;

  }

  .footerBox4 {
    width: 300px;
    height: 100px;
    border-radius: 5px;
    margin-top: 25px;
    background-image: url(./assets/discord_before.jpg);
    background-size: cover;
    margin-left: 15px;
    border: none;
  }

  .footerBox4:hover {
    background-image: url(./assets/discord_after.jpg);
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    color: rgb(226, 226, 226);
    cursor: pointer;

  }

  .iconsSection1 {
    display: flex;
    flex-flow: row nowrap;
  }

  .iconsSection2 {
    display: flex;
    flex-flow: row nowrap;
  }

  .footerDetails {
    color: black;
    font-size: 18px;
    font-weight: bolder;
    width: 300px;
    height: 100px;
    text-align: center;
    padding-left: 50px;
    padding-top: 30px;


  }

  .footerDetails2 {
    color: rgb(75, 75, 75);
    font-size: 12px;

  }

  .footerDetails:hover {
    color: rgb(226, 226, 226);
  }

  .footerDetails p {

    font-size: 14px;
    color: rgb(161, 161, 161);
    margin-top: -3px;
    font-family: "Nunito", sans-serif;

  }

  .footerDetails2:hover {
    color: rgb(201, 201, 201);
  }

  .elementSocial {
    animation: floating 4s ease-in-out infinite;
    width: 21%;
    z-index: 100;
    cursor: pointer;
    transition: transform .2s;
  }

  .elementSocial {
    position: fixed;
    bottom: 8%;
    /* Adjust as needed */
    right: 8%;
    /* Adjust as needed */
    z-index: 10000000;
    animation: rotate-left-to-right 1s ease-in-out infinite alternate;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  
  iframe {
    width: 100%;
    height: 100%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    /* Optional: shadow for a nicer appearance */
 
  }

  .iframeDiv {
    margin: 0;
    padding: 0;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #3f82cf;
  }

  .sky {
    width: 100%;
    height: 20vh;
    background-color: #3f82cf;
  }
  .miniBarMain {
    display: none;
  }

  .landD {
    display: none;
  }

  .allwrapLands {
    display: none;
  }

  .logoLoad {
    width: 18%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-bottom: 2%;
    animation: zoomInOut 4s infinite;

  }

  @keyframes zoomInOut {
    0% {
      transform: scale(1);
    }

    50% {
      transform: scale(1.1);
      /* Zoom in */
    }

    100% {
      transform: scale(1);
      /* Zoom out */
    }
  }

  .page-loader2 {
    width: 100%;
    height: 100vh;
    background: rgb(52, 52, 52);
    background: radial-gradient(circle, rgba(52, 52, 52, 1) 0%, rgba(22, 22, 22, 1) 50%, rgba(0, 0, 0, 1) 100%);
    position: absolute;
    z-index: 10000000;
    position: fixed;
    opacity: 0.5px;
  }

  .parent-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    flex-flow: column nowrap;
    /* Adjust the height based on your layout */
  }

  @keyframes l5 {

    0%,
    11% {
      background-position: 35% 35%
    }

    14%,
    36% {
      background-position: 65% 35%
    }

    38%,
    61% {
      background-position: 65% 65%
    }

    64%,
    86% {
      background-position: 35% 65%
    }

    88%,
    100% {
      background-position: 35% 35%
    }
  }

  /* src/App.css */
  .fade-container {
    background-color: gold;
    animation: fadeOut 2s forwards;
    /* Make sure the container covers the whole page */
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .fade-out {
    animation: fadeOut 2s forwards;
  }

  @keyframes fadeOut {
    from {
      opacity: 1;
    }

    to {
      opacity: 0;
      background-color: transparent;
    }
  }

  h1 {
    color: white;
    font-size: 2rem;
  }

  .light {
    height: 100vh;
    position: relative;
  }

  ::-webkit-scrollbar {
    display: none;
  }

  .mainBg {
    height: 100vh;
    /* Full screen height */
    width: 100vw;
    /* Full screen width */
    position: absolute;
    /* Absolute positioning */
    top: 0;
    /* Aligns top to the top of the viewport */
    left: 0;
    /* Aligns left to the left of the viewport */
    object-fit: cover;
    /* Ensures the image covers the entire area without stretching */
    object-position: center;
    /* Centers the image */
  }


  .button {
    position: absolute;
    z-index: 1;
    margin-left: 47.8%;
    margin-top: 23.3%;
    background-color: rgba(133, 106, 72, 0);
    width: 9%;
    height: 35%;
    cursor: pointer;
  }


  .light2 {
    display: flex;
    justify-content: space-between;
    width: 40vw;
    margin-left: auto;
    margin-right: auto;
    background-color: aquamarine;

    width: 180px;
    height: 600px;
    overflow-y: scroll;
  }

  .land1 {
    width: 50%;
    height: 50%;
    width: 140px;
    height: 140px;
    margin: 10px;

    background-color: #a8a8df;
  }

  @keyframes float {

    0%,
    100% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-20px);
    }
  }

  .land2 {
    width: 50%;
    height: 50%;
  }

  .land3 {
    width: 50%;
    height: 50%;
  }

  .images3 {
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
  }

  .images3 img {
    width: 45%;
    margin-right: 5%;
    margin-left: 5%;
    position: relative;
  }

  .imgsMainDiv {
    position: relative;
    width: 100%;
    /* Ensure the container takes up the full width */
    height: 100vh;
    /* Ensure the container takes up the full viewport height */
    overflow-x: auto;
    overflow-y: hidden;
    /* Optionally hide vertical scrollbar */
    background: rgb(0, 0, 0);
    /*background: linear-gradient(180deg, rgba(81, 156, 0, 1) 0%, rgba(81, 156, 0, 1) 50%, rgba(52, 99, 0, 1) 100%)*/;
  }

  .bgImgs {
    position: absolute;
    top: 0;
    left: 0;
    width: 230%;
    /* Full viewport width */
    height: auto;
    /* Maintain aspect ratio */
  }

  .img1 {
    width: 31%;
    position: absolute;
    z-index: 5;
   margin-top: 9.8%;
    margin-left: 22%;
    animation: floating 8s ease-in-out infinite;
  }

  .img2 {
    width: 55%;
    position: absolute;
    z-index: 5;
    margin-left: 163%;
    margin-top: 1%;
    animation: floating 8.2s ease-in-out infinite;
  }

  .img3 {
    width: 62%;
    position: absolute;
    z-index: 5;
    margin-left: 84%;
    margin-top: 4%;
    animation: floating 8s ease-in-out infinite;
  }

  @keyframes floating {
    0% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-10px);
    }

    100% {
      transform: translateY(0);
    }
  }

  .element1 {
    width: 8.8%;
    position: absolute;
    z-index: 84;
    margin-left: 6.5%;
    animation: floating 5s ease-in-out infinite;
  }

  .element2 {
    width: 6%;
    position: absolute;
    z-index: 84;
    margin-left: 62.5%;
    margin-top: 2%;
    animation: floating 10s ease-in-out infinite;
  }

  .element3 {
    width: 15%;
    position: absolute;
    z-index: 84;
    margin-left: 57%;
    margin-top: 10%;
    animation: floating 12s ease-in-out infinite;
  }

  .element4 {
    width: 6.5%;
    position: absolute;
    z-index: 84;
    margin-left: 80%;
    margin-top: 20%;
    animation: floating 13s ease-in-out infinite;
  }

  .element5 {
    width: 13%;
    position: absolute;
    z-index: 84;
    margin-left: 135%;
    margin-top: 18.2%;
    animation: floating 7s ease-in-out infinite;
  }

  .element6 {
    width: 15.5%;
    position: absolute;
    z-index: 4;
    margin-left: 166.8%;
    margin-top: 13.5%;
    animation: floating 7s ease-in-out infinite;
  }

  .element7 {
    width: 11%;
    position: absolute;
    z-index: 4;
    margin-left: 205.5%;
    margin-top: 5.5%;
    animation: floating 7s ease-in-out infinite;
  }

  .element8 {
    width: 5.5%;
    position: absolute;
    z-index: 100;
    margin-left: 210%;
    margin-top: 15.2%;
    animation: floating 10s ease-in-out infinite;
    cursor: pointer;
  }

  .element9 {
    width: 6.5%;
    position: absolute;
    z-index: 4;
    margin-left: 55.2%;
    margin-top: 32.5%;
    animation: floating 10s ease-in-out infinite;
  }

  .element10 {
    width: 6.5%;
    position: absolute;
    z-index: 5;
    margin-left: 46.5%;
    margin-top: 33.5%;
    animation: rotate-left-to-right 1s ease-in-out infinite alternate;
  }

  .element11 {
    width: 5%;
    position: absolute;
    z-index: 5;
    margin-left: 4%;
    margin-top: 30%;
    animation: rotate-left-to-right 1s ease-in-out infinite alternate;
  }


  @keyframes rotate-left-to-right {

    0% {
      transform: rotateZ(12deg);
    }

    100% {
      transform: rotateZ(8deg);
    }

  }

  /* In your CSS file */
  .goldenLight {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(255, 233, 110);
    opacity: 0;
    animation: emitLight 3s linear forwards;
    z-index: 9999;
    /* Ensure the golden light is above other content */
  }

  .page-loader {
    width: 100%;
    height: 100vh;
    background-color: rgb(255, 233, 110);
    position: absolute;
    z-index: 100;
    position: fixed;
    opacity: 0.5px;
  }


  @keyframes emitLight {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  .table {
    width: 100%;
    display: block;
    /* Ensure it's displayed as a block element */
    margin: 0;
    /* Remove default margin */
  }

  .bookElement {
    position: absolute;
    width: 52%;
    margin-left: 48%;
    margin-top: 25.5%;
    transform: rotate(-7deg);
    /* Initial rotation */
    cursor: pointer;
    transition: all 1s ease;
    /* Adding transition for all properties */
    padding: 0px;
    z-index: 5;
  }

  .bookElement.left {
    margin-left: 20%;
    /* Move to the left */
  }

  .bookElement.up {
    margin-top: 10%;
    /* Move up slightly */
    transform: rotate(0deg);
    /* Rotate to 30deg */
  }

  .bookElement.moving {
    margin-left: calc(42% - 26%);
    /* Adjustment based on the actual size of the book element */
    margin-top: 12%;
    transform: rotate(0deg);
    z-index: 2;
    width: 67%;
    position: fixed;

  }

  .backgroundOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    opacity: 0;
    transition: opacity 0.5s ease;
    z-index: 1;
  }


  .game {
    z-index: 1;
    position: absolute;
    width: 11%;
    margin-left: 40%;
    margin-top: 20%;
    rotate: 18deg;
  }

  /* Styles for the popup */
  .popup {
    transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 100000000000;
    background-color: rgba(0, 0, 0, 0.726);
  }

  .bookOpened {
    width: 85%;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .close {
    position: absolute;
    top: 70px;
    right: 100px;
    cursor: pointer;
    font-size: 25px;
    z-index: 50;
    color: white;
    transition: 0.2s ease-in-out;
  }

  .close:hover {
    color: rgb(156, 156, 156);
  }

  .allwrapAbout {
    background: url('./assets/7639148.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
  }


  .allwrapSocial {
    background-color: rgb(0, 0, 0);
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
  }

  .allwrapAbout2 {
    min-height: 100vh;
    height: max-content;
  }

  /* Initially hide the navDiv elements */
  .headers2 .right .navDiv {
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
  }

  /* Show navDiv elements when hovering over .headers2 or .logo */
  .headers2:hover .right .navDiv {
    opacity: 1;
  }

  .logo:hover~.right .navDiv {
    opacity: 1;
  }

  /* Styling for the logo image */
  .logoPic {
    width: 120px;
    /* Adjust width as needed */
    cursor: pointer;
  }

  /* Basic styling for .navDiv */
  .navDiv {
    margin-left: 25px;
    color: white;
    font-size: 15px;
    font-weight: 400;
    cursor: pointer;
    transition: color 0.2s ease-in-out;
    /* Add smooth color transition */
  }

  .navDiv:hover {
    color: #c5c4c4;
  }

  .headers {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10000;
    background: rgb(0, 0, 0);
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.7315301120448179) 0%, rgba(0, 0, 0, 0.5942752100840336) 50%, rgba(0, 0, 0, 0.28335084033613445) 100%);
  }


  /* Additional styling for .headers2 */
  .headers2 {
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin: 10px auto;

  }

  .right {
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
  }

  .aboutSection {
    width: 100%;
    margin-top: 9%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .carouselContainer {
    margin-top: 9%;
  }


  .sec1Img {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .sec1Img img {
    width: 300px;
    height: 300px;
    background-color: rgb(188, 247, 207);
    border: 5px solid rgb(81, 253, 136);
    border-radius: 30px;
    rotate: 6deg;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .sec2Img {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .sec2Img img {
    width: 300px;
    height: 300px;
    background-color: rgb(188, 247, 207);
    border: 5px solid rgb(81, 253, 136);
    border-radius: 30px;
    rotate: -6deg;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .sec3Img {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .sec3Img img {
    width: 300px;
    height: 300px;
    background-color: rgb(188, 247, 207);
    border: 5px solid rgb(81, 253, 136);
    border-radius: 30px;
    rotate: 6deg;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .title {
    color: #ffffff;
    font-size: 65px;
    margin-bottom: 1%;
  }

  .aboutSection p {
    color: rgb(255, 255, 255);
    font-family: "Nunito", sans-serif;
  }

  .eachSections1 {
    display: flex;
    justify-content: space-evenly;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    width: 90%;
    background-color: #3f82cf;
    margin-left: auto;
    margin-right: auto;
    padding: 20px;
    border-radius: 50px;
    rotate: -2deg;
    border: 8px solid white;
    padding: 30px;
    margin-bottom: 10%;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px;
  }

  #t2 {
    text-align: right;
  }

  .eachSections2 {
    display: flex;
    justify-content: space-evenly;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    width: 90%;
    background-color: #ff6f2f;
    margin-left: auto;
    margin-right: auto;
    padding: 20px;
    border-radius: 50px;
    rotate: 2deg;
    border: 8px solid white;
    padding: 30px;
    margin-bottom: 10%;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px;
  }

  .eachSections3 {
    display: flex;
    justify-content: space-evenly;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    width: 90%;
    background-color: #805eff;
    margin-left: auto;
    margin-right: auto;
    padding: 20px;
    border-radius: 50px;
    rotate: -2deg;
    border: 8px solid white;
    padding: 30px;
    margin-bottom: 10%;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px;

  }

  .eachSections {
    margin-top: 5%;
  }

  .whatsapp {
    animation: floating 4s ease-in-out infinite;
    width: 50px;
    height: 50px;
    z-index: 100;
    cursor: pointer;
    transition: transform .2s;
  }

  .whatsapp:hover {
    transform: scale(1.5);
  }

  .whatsappDiv {
    position: fixed;
    bottom: 30px;
    /* Adjust as needed */
    right: 30px;
    /* Adjust as needed */
    z-index: 10000000;
  }

  .detailsEgg {
    flex: 0 0 100%;
    display: none;
  }

  .detailsEgg.active {
    display: block;
  }

  .arrow {
    cursor: pointer;
    width: 80px;
    height: 80px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .left {
    margin-right: -30px;
    z-index: 5;
  }

  .right-2 {
    margin-left: -30px;
  }

  @keyframes pop2 {
    0% {
      transform: scale(0);
    }

    100% {
      transform: scale(1);
    }
  }


  .detailsEggSub {
    display: flex;
    justify-content: space-between;
    background-color: #275a72;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 20px;
    padding: 20px;
  }

  .detailsEggSub2 {
    display: flex;
    justify-content: space-between;
    background-color: #c0692f;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 20px;
    padding: 20px;

  }

  .detailsEggSub3 {
    display: flex;
    justify-content: space-between;
    background-color: #3f3c50;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 20px;
    padding: 20px;

  }

  .detailsEggSub4 {
    display: flex;
    justify-content: space-between;
    background-color: #cc3a46;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 20px;
    padding: 20px;

  }

  .detailsEggSub5 {
    display: flex;
    justify-content: space-between;
    background-color: #c56300;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 20px;
    padding: 20px;

  }

  .detailsEggSub6 {
    display: flex;
    justify-content: space-between;
    background-color: #39509c;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 20px;
    padding: 20px;

  }

  .detailsEggSub7 {
    display: flex;
    justify-content: space-between;
    background-color: #ce4d3f;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 20px;
    padding: 20px;

  }

  .detailsEggSub8 {
    display: flex;
    justify-content: space-between;
    background-color: #b65269;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 20px;
    padding: 20px;

  }

  .detailsEggSub9 {
    display: flex;
    justify-content: space-between;
    background-color: #37772d;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 20px;
    padding: 20px;

  }

  .detailsEggSub10 {
    display: flex;
    justify-content: space-between;
    background-color: #853394;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 20px;
    padding: 20px;

  }


  .eggMain {
    width: 40%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    animation: pop2 0.7s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;

  }

  .eggT {
    font-size: 40px;
    color: white;
    margin-bottom: 10px;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
  }

  .eggP {
    font-family: "Nunito", sans-serif;
    width: 95%;
    color: white;
    background-color: #fbfbfc27;
    border-radius: 7px;
    padding: 20px;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.096), 0px -5px 35px rgba(255, 255, 255, 0.3);
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }

  .contentsEgg {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .carouselContainer {
    display: flex;
  }

  .littleEggsMain {
    display: flex;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 3%;
  }

  .littleEggs {
    width: 200px;
    margin-top: -50px;
    z-index: 10;
  }

  .littleEggs img {
    width: 75px;
    height: 75px;
    display: flex;
    transition: transform .2s;
  }

  .littleEggs img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  .littleEggs div {
    font-size: 14px;
    color: rgb(0, 0, 0);
    text-align: center;
  }

  /*.............. FOOTER ..............*/

  .footer {
    margin-top: 9%;
    margin-left: 5%;
  }

  .footerHeader {
    color: rgb(255, 255, 255);
    font-size: 50px;
  }

  .footerP {
    color: rgb(167, 167, 167);
    font-size: 15px;
    width: 50%;
    margin-top: 20px;
    font-family: "Nunito", sans-serif;
  }

  .line {
    width: 50%;
    height: 1px;
    background-color: rgba(219, 219, 219, 0.24);
    margin-top: 15px;
  }

  .footerBox1 {
    width: 300px;
    height: 100px;
    border-radius: 5px;
    margin-top: 25px;
    background-image: url(./assets/email_before.jpg);
    background-size: cover;
    border: none;
  }

  .footerBox1:hover {
    background-image: url(./assets/email_after.jpg);
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    color: rgb(226, 226, 226);
    cursor: pointer;

  }

  .footerBox2 {
    width: 300px;
    height: 100px;
    border-radius: 5px;
    margin-top: 25px;
    background-image: url(./assets/instagram_before.jpg);
    background-size: cover;
    margin-left: 15px;
    border: none;
  }

  .footerBox2:hover {
    background-image: url(./assets/instagram_after.jpg);
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    color: rgb(226, 226, 226);
    cursor: pointer;

  }

  .footerBox3 {
    width: 300px;
    height: 100px;
    border-radius: 5px;
    margin-top: 25px;
    background-image: url(./assets/twitter_before.jpg);
    background-size: cover;
    border: none;
  }

  .footerBox3:hover {
    background-image: url(./assets/twitter_after.jpg);
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    color: rgb(226, 226, 226);
    cursor: pointer;

  }

  .footerBox4 {
    width: 300px;
    height: 100px;
    border-radius: 5px;
    margin-top: 25px;
    background-image: url(./assets/discord_before.jpg);
    background-size: cover;
    margin-left: 15px;
    border: none;
  }

  .footerBox4:hover {
    background-image: url(./assets/discord_after.jpg);
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    color: rgb(226, 226, 226);
    cursor: pointer;

  }

  .iconsSection1 {
    display: flex;
    flex-flow: row nowrap;
  }

  .iconsSection2 {
    display: flex;
    flex-flow: row nowrap;
  }

  .footerDetails {
    color: black;
    font-size: 18px;
    font-weight: bolder;
    width: 300px;
    height: 100px;
    text-align: center;
    padding-left: 50px;
    padding-top: 30px;


  }

  .footerDetails2 {
    color: rgb(75, 75, 75);
    font-size: 12px;

  }

  .footerDetails:hover {
    color: rgb(226, 226, 226);
  }

  .footerDetails p {

    font-size: 14px;
    color: rgb(161, 161, 161);
    margin-top: -3px;
    font-family: "Nunito", sans-serif;

  }

  .footerDetails2:hover {
    color: rgb(201, 201, 201);
  }

  .elementSocial {
    animation: floating 4s ease-in-out infinite;
    width: 21%;
    z-index: 100;
    cursor: pointer;
    transition: transform .2s;
  }

  .elementSocial {
    position: fixed;
    bottom: 8%;
    /* Adjust as needed */
    right: 8%;
    /* Adjust as needed */
    z-index: 10000000;
    animation: rotate-left-to-right 1s ease-in-out infinite alternate;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1919px) {

  iframe {
    width: 100%;
    height: 100%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    /* Optional: shadow for a nicer appearance */
    margin-top: 10px;
  }

  .iframeDiv {
    margin: 0;
    padding: 0;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #3f82cf;
  }

  .sky {
    width: 100%;
    height: 5vh;
    background-color: #3f82cf;
  }

  .miniBarMain {
    display: none;
  }

  .landD {
    display: none;
  }

  .landD {
    display: none;
  }

  .allwrapLands {
    display: none;
  }

  .logoLoad {
    width: 18%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-bottom: 2%;
    animation: zoomInOut 4s infinite;

  }

  @keyframes zoomInOut {
    0% {
      transform: scale(1);
    }

    50% {
      transform: scale(1.1);
      /* Zoom in */
    }

    100% {
      transform: scale(1);
      /* Zoom out */
    }
  }

  .page-loader2 {
    width: 100%;
    height: 100vh;
    background: rgb(52, 52, 52);
    background: radial-gradient(circle, rgba(52, 52, 52, 1) 0%, rgba(22, 22, 22, 1) 50%, rgba(0, 0, 0, 1) 100%);
    position: absolute;
    z-index: 10000000;
    position: fixed;
    opacity: 0.5px;
  }

  .parent-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    flex-flow: column nowrap;
    /* Adjust the height based on your layout */
  }

  @keyframes l5 {

    0%,
    11% {
      background-position: 35% 35%
    }

    14%,
    36% {
      background-position: 65% 35%
    }

    38%,
    61% {
      background-position: 65% 65%
    }

    64%,
    86% {
      background-position: 35% 65%
    }

    88%,
    100% {
      background-position: 35% 35%
    }
  }

  /* src/App.css */
  .fade-container {
    background-color: gold;
    animation: fadeOut 2s forwards;
    /* Make sure the container covers the whole page */
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .fade-out {
    animation: fadeOut 2s forwards;
  }

  @keyframes fadeOut {
    from {
      opacity: 1;
    }

    to {
      opacity: 0;
      background-color: transparent;
    }
  }

  h1 {
    color: white;
    font-size: 2rem;
  }

  .light {
    height: 100vh;
    position: relative;
  }

  ::-webkit-scrollbar {
    display: none;
  }

  .mainBg {
    width: 100%;
    position: absolute;
  }

  .button {
    position: absolute;
    z-index: 1;
    margin-left: 47.8%;
    margin-top: 23.3%;
    background-color: rgba(133, 106, 72, 0);
    width: 9%;
    height: 35%;
    cursor: pointer;
  }


  .light2 {
    display: flex;
    justify-content: space-between;
    width: 40vw;
    margin-left: auto;
    margin-right: auto;
    background-color: aquamarine;

    width: 180px;
    height: 600px;
    overflow-y: scroll;
  }

  .land1 {
    width: 50%;
    height: 50%;
    width: 140px;
    height: 140px;
    margin: 10px;

    background-color: #a8a8df;
  }

  @keyframes float {

    0%,
    100% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-20px);
    }
  }

  .land2 {
    width: 50%;
    height: 50%;
  }

  .land3 {
    width: 50%;
    height: 50%;
  }

  .images3 {
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
  }

  .images3 img {
    width: 45%;
    margin-right: 5%;
    margin-left: 5%;
    position: relative;
  }

  .imgsMainDiv {
    position: relative;
    width: 100%;
    /* Ensure the container takes up the full width */
    height: 100vh;
    /* Ensure the container takes up the full viewport height */
    overflow-x: auto;
    overflow-y: hidden;
    /* Optionally hide vertical scrollbar */
    background: rgb(0, 0, 0);
    /*background: linear-gradient(180deg, rgba(81, 156, 0, 1) 0%, rgba(81, 156, 0, 1) 50%, rgba(52, 99, 0, 1) 100%)*/;
  }

  .bgImgs {
    position: absolute;
    top: 0;
    left: 0;
    width: 230%;
    /* Full viewport width */
    height: auto;
    /* Maintain aspect ratio */
  }

  .img1 {
    width: 31%;
    position: absolute;
    z-index: 5;
    margin-top: 9.8%;
    margin-left: 22%;
    animation: floating 8s ease-in-out infinite;
  }

  .img2 {
    width: 55%;
    position: absolute;
    z-index: 5;
    margin-left: 163%;
    margin-top: 1%;
    animation: floating 8.2s ease-in-out infinite;
  }

  .img3 {
    width: 62%;
    position: absolute;
    z-index: 5;
    margin-left: 84%;
    margin-top: 4%;
    animation: floating 8s ease-in-out infinite;
  }

  @keyframes floating {
    0% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-10px);
    }

    100% {
      transform: translateY(0);
    }
  }

  .element1 {
    width: 8.8%;
    position: absolute;
    z-index: 84;
    margin-left: 6.5%;
    animation: floating 5s ease-in-out infinite;
  }

  .element2 {
    width: 6%;
    position: absolute;
    z-index: 84;
    margin-left: 62.5%;
    margin-top: 2%;
    animation: floating 10s ease-in-out infinite;
  }

  .element3 {
    width: 15%;
    position: absolute;
    z-index: 84;
    margin-left: 57%;
    margin-top: 10%;
    animation: floating 12s ease-in-out infinite;
  }

  .element4 {
    width: 6.5%;
    position: absolute;
    z-index: 84;
    margin-left: 80%;
    margin-top: 20%;
    animation: floating 13s ease-in-out infinite;
  }

  .element5 {
    width: 13%;
    position: absolute;
    z-index: 84;
    margin-left: 135%;
    margin-top: 18.2%;
    animation: floating 7s ease-in-out infinite;
  }

  .element6 {
    width: 15.5%;
    position: absolute;
    z-index: 4;
    margin-left: 166.8%;
    margin-top: 13.5%;
    animation: floating 7s ease-in-out infinite;
  }

  .element7 {
    width: 11%;
    position: absolute;
    z-index: 4;
    margin-left: 205.5%;
    margin-top: 5.5%;
    animation: floating 7s ease-in-out infinite;
  }

  .element8 {
    width: 5.5%;
    position: absolute;
    z-index: 100;
    margin-left: 210%;
    margin-top: 15.2%;
    animation: floating 10s ease-in-out infinite;
    cursor: pointer;
  }

  .element9 {
    width: 6.5%;
    position: absolute;
    z-index: 4;
    margin-left: 55.2%;
    margin-top: 32.5%;
    animation: floating 10s ease-in-out infinite;
  }

  .element10 {
    width: 6.5%;
    position: absolute;
    z-index: 5;
    margin-left: 46.5%;
    margin-top: 33.5%;
    animation: rotate-left-to-right 1s ease-in-out infinite alternate;
  }

  .element11 {
    width: 5%;
    position: absolute;
    z-index: 5;
    margin-left: 4%;
    margin-top: 30%;
    animation: rotate-left-to-right 1s ease-in-out infinite alternate;
  }


  @keyframes rotate-left-to-right {

    0% {
      transform: rotateZ(12deg);
    }

    100% {
      transform: rotateZ(8deg);
    }

  }

  /* In your CSS file */
  .goldenLight {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(255, 233, 110);
    opacity: 0;
    animation: emitLight 3s linear forwards;
    z-index: 9999;
    /* Ensure the golden light is above other content */
  }

  .page-loader {
    width: 100%;
    height: 100vh;
    background-color: rgb(255, 233, 110);
    position: absolute;
    z-index: 100;
    position: fixed;
    opacity: 0.5px;
  }


  @keyframes emitLight {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  .table {
    width: 100%;
    display: block;
    /* Ensure it's displayed as a block element */
    margin: 0;
    /* Remove default margin */
  }

  .bookElement {
    position: absolute;
    width: 52%;
    margin-left: 48%;
    margin-top: 25%;
    transform: rotate(-7deg);
    /* Initial rotation */
    cursor: pointer;
    transition: all 1s ease;
    /* Adding transition for all properties */
    padding: 0px;
    z-index: 5;
  }

  .bookElement.left {
    margin-left: 20%;
    /* Move to the left */
  }

  .bookElement.up {
    margin-top: 10%;
    /* Move up slightly */
    transform: rotate(0deg);
    /* Rotate to 30deg */
  }

  .bookElement.moving {
    margin-left: calc(42% - 26%);
    /* Adjustment based on the actual size of the book element */
    margin-top: 5%;
    transform: rotate(0deg);
    z-index: 2;
    width: 67%;
    position: fixed;

  }

  .backgroundOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    opacity: 0;
    transition: opacity 0.5s ease;
    z-index: 1;
  }


  .game {
    z-index: 1;
    position: absolute;
    width: 11%;
    margin-left: 40%;
    margin-top: 20%;
    rotate: 18deg;
  }

  /* Styles for the popup */
  .popup {
    transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 100000000000;
    background-color: rgba(0, 0, 0, 0.726);
  }

  .bookOpened {
    width: 85%;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .close {
    position: absolute;
    top: 70px;
    right: 100px;
    cursor: pointer;
    font-size: 25px;
    z-index: 50;
    color: white;
    transition: 0.2s ease-in-out;
  }

  .close:hover {
    color: rgb(156, 156, 156);
  }

  .allwrapAbout {
    background: url('./assets/7639148.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
  }


  .allwrapSocial {
    background-color: rgb(0, 0, 0);
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
  }

  .allwrapAbout2 {
    min-height: 100vh;
    height: max-content;
  }

  /* Initially hide the navDiv elements */
  .headers2 .right .navDiv {
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
  }

  /* Show navDiv elements when hovering over .headers2 or .logo */
  .headers2:hover .right .navDiv {
    opacity: 1;
  }

  .logo:hover~.right .navDiv {
    opacity: 1;
  }

  /* Styling for the logo image */
  .logoPic {
    width: 120px;
    /* Adjust width as needed */
    cursor: pointer;
  }

  /* Basic styling for .navDiv */
  .navDiv {
    margin-left: 25px;
    color: white;
    font-size: 15px;
    font-weight: 400;
    cursor: pointer;
    transition: color 0.2s ease-in-out;
    /* Add smooth color transition */
  }

  .navDiv:hover {
    color: #c5c4c4;
  }

  .headers {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10000;
    background: rgb(0, 0, 0);
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.7315301120448179) 0%, rgba(0, 0, 0, 0.5942752100840336) 50%, rgba(0, 0, 0, 0.28335084033613445) 100%);
  }


  /* Additional styling for .headers2 */
  .headers2 {
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin: 10px auto;

  }

  .right {
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
  }

  .aboutSection {
    width: 80%;
    margin-top: 9%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .carouselContainer {
    margin-top: 9%;
  }

  .sec1Img {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .sec1Img img {
    width: 400px;
    height: 400px;
    background-color: rgb(188, 247, 207);
    border: 5px solid rgb(81, 253, 136);
    border-radius: 30px;
    rotate: 6deg;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .sec2Img {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .sec2Img img {
    width: 400px;
    height: 400px;
    background-color: rgb(188, 247, 207);
    border: 5px solid rgb(81, 253, 136);
    border-radius: 30px;
    rotate: -6deg;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .sec3Img {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .sec3Img img {
    width: 400px;
    height: 400px;
    background-color: rgb(188, 247, 207);
    border: 5px solid rgb(81, 253, 136);
    border-radius: 30px;
    rotate: 6deg;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .title {
    color: #ffffff;
    font-size: 65px;
    margin-bottom: 1%;
  }

  .aboutSection p {
    color: rgb(255, 255, 255);
    font-family: "Nunito", sans-serif;
  }

  .eachSections1 {
    display: flex;
    justify-content: space-evenly;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    width: 90%;
    background-color: #3f82cf;
    margin-left: auto;
    margin-right: auto;
    padding: 20px;
    border-radius: 50px;
    rotate: -2deg;
    border: 8px solid white;
    padding: 30px;
    margin-bottom: 10%;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px;
  }

  #t2 {
    text-align: right;
  }

  .eachSections2 {
    display: flex;
    justify-content: space-evenly;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    width: 90%;
    background-color: #ff6f2f;
    margin-left: auto;
    margin-right: auto;
    padding: 20px;
    border-radius: 50px;
    rotate: 2deg;
    border: 8px solid white;
    padding: 30px;
    margin-bottom: 10%;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px;
  }

  .eachSections3 {
    display: flex;
    justify-content: space-evenly;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    width: 90%;
    background-color: #805eff;
    margin-left: auto;
    margin-right: auto;
    padding: 20px;
    border-radius: 50px;
    rotate: -2deg;
    border: 8px solid white;
    padding: 30px;
    margin-bottom: 10%;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px;

  }

  .eachSections {
    margin-top: 5%;
  }

  .whatsapp {
    animation: floating 4s ease-in-out infinite;
    width: 50px;
    height: 50px;
    z-index: 100;
    cursor: pointer;
    transition: transform .2s;
  }

  .whatsapp:hover {
    transform: scale(1.5);
  }

  .whatsappDiv {
    position: fixed;
    bottom: 30px;
    /* Adjust as needed */
    right: 30px;
    /* Adjust as needed */
    z-index: 10000000;
  }

  .detailsEgg {
    flex: 0 0 100%;
    display: none;
  }

  .detailsEgg.active {
    display: block;
  }

  .arrow {
    cursor: pointer;
    width: 80px;
    height: 80px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .left {
    margin-right: -30px;
    z-index: 5;
  }

  .right-2 {
    margin-left: -30px;
  }

  @keyframes pop2 {
    0% {
      transform: scale(0);
    }

    100% {
      transform: scale(1);
    }
  }


  .detailsEggSub {
    display: flex;
    justify-content: space-between;
    background-color: #275a72;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 20px;

  }

  .detailsEggSub2 {
    display: flex;
    justify-content: space-between;
    background-color: #c0692f;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 20px;
  }

  .detailsEggSub3 {
    display: flex;
    justify-content: space-between;
    background-color: #3f3c50;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 20px;
  }

  .detailsEggSub4 {
    display: flex;
    justify-content: space-between;
    background-color: #cc3a46;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 20px;
  }

  .detailsEggSub5 {
    display: flex;
    justify-content: space-between;
    background-color: #c56300;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 20px;
  }

  .detailsEggSub6 {
    display: flex;
    justify-content: space-between;
    background-color: #39509c;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 20px;
  }

  .detailsEggSub7 {
    display: flex;
    justify-content: space-between;
    background-color: #ce4d3f;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 20px;
  }

  .detailsEggSub8 {
    display: flex;
    justify-content: space-between;
    background-color: #b65269;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 20px;
  }

  .detailsEggSub9 {
    display: flex;
    justify-content: space-between;
    background-color: #37772d;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 20px;
  }

  .detailsEggSub10 {
    display: flex;
    justify-content: space-between;
    background-color: #853394;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 20px;
  }


  .eggMain {
    width: 40%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    animation: pop2 0.7s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;

  }

  .eggT {
    font-size: 50px;
    color: white;
    margin-bottom: 10px;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
  }

  .eggP {
    font-family: "Nunito", sans-serif;
    width: 80%;
    color: white;
    background-color: #fbfbfc27;
    border-radius: 7px;
    padding: 20px;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.096), 0px -5px 35px rgba(255, 255, 255, 0.3);
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }

  .contentsEgg {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .carouselContainer {
    display: flex;
  }

  .littleEggsMain {
    display: flex;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 3%;
  }

  .littleEggs {
    width: 300px;
    margin-top: -70px;
    z-index: 10;
  }

  .littleEggs img {
    width: 100px;
    height: 100px;
    display: flex;
    transition: transform .2s;
  }

  .littleEggs img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  .littleEggs div {
    font-size: 15px;
    color: rgb(0, 0, 0);
    text-align: center;
  }

  /*.............. FOOTER ..............*/

  .footer {
    margin-top: 9%;
    margin-left: 5%;
  }

  .footerHeader {
    color: rgb(255, 255, 255);
    font-size: 50px;
  }

  .footerP {
    color: rgb(167, 167, 167);
    font-size: 15px;
    width: 50%;
    margin-top: 20px;
    font-family: "Nunito", sans-serif;
  }

  .line {
    width: 50%;
    height: 1px;
    background-color: rgba(219, 219, 219, 0.24);
    margin-top: 15px;
  }

  .footerBox1 {
    width: 300px;
    height: 100px;
    border-radius: 5px;
    margin-top: 25px;
    background-image: url(./assets/email_before.jpg);
    background-size: cover;
    border: none;
  }

  .footerBox1:hover {
    background-image: url(./assets/email_after.jpg);
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    color: rgb(226, 226, 226);
    cursor: pointer;

  }

  .footerBox2 {
    width: 300px;
    height: 100px;
    border-radius: 5px;
    margin-top: 25px;
    background-image: url(./assets/instagram_before.jpg);
    background-size: cover;
    margin-left: 15px;
    border: none;
  }

  .footerBox2:hover {
    background-image: url(./assets/instagram_after.jpg);
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    color: rgb(226, 226, 226);
    cursor: pointer;

  }

  .footerBox3 {
    width: 300px;
    height: 100px;
    border-radius: 5px;
    margin-top: 25px;
    background-image: url(./assets/twitter_before.jpg);
    background-size: cover;
    border: none;
  }

  .footerBox3:hover {
    background-image: url(./assets/twitter_after.jpg);
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    color: rgb(226, 226, 226);
    cursor: pointer;

  }

  .footerBox4 {
    width: 300px;
    height: 100px;
    border-radius: 5px;
    margin-top: 25px;
    background-image: url(./assets/discord_before.jpg);
    background-size: cover;
    margin-left: 15px;
    border: none;
  }

  .footerBox4:hover {
    background-image: url(./assets/discord_after.jpg);
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    color: rgb(226, 226, 226);
    cursor: pointer;

  }

  .iconsSection1 {
    display: flex;
    flex-flow: row nowrap;
  }

  .iconsSection2 {
    display: flex;
    flex-flow: row nowrap;
  }

  .footerDetails {
    color: black;
    font-size: 18px;
    font-weight: bolder;
    width: 300px;
    height: 100px;
    text-align: center;
    padding-left: 50px;
    padding-top: 30px;


  }

  .footerDetails2 {
    color: rgb(75, 75, 75);
    font-size: 12px;

  }

  .footerDetails:hover {
    color: rgb(226, 226, 226);
  }

  .footerDetails p {

    font-size: 14px;
    color: rgb(161, 161, 161);
    margin-top: -3px;
    font-family: "Nunito", sans-serif;

  }

  .footerDetails2:hover {
    color: rgb(201, 201, 201);
  }

  .elementSocial {
    animation: floating 4s ease-in-out infinite;
    width: 21%;
    z-index: 100;
    cursor: pointer;
    transition: transform .2s;
  }

  .elementSocial {
    position: fixed;
    bottom: 8%;
    /* Adjust as needed */
    right: 15%;
    /* Adjust as needed */
    z-index: 10000000;
    animation: rotate-left-to-right 1s ease-in-out infinite alternate;
  }
}

@media screen and (min-width: 1920px) {
  
iframe {
  width: 120%;
  height: 120%;
  margin-top: 20px;
 }

 .iframeDiv{
  margin: 0;
  padding: 0;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #3f82cf;
  
 }

.sky{
  width: 100%;
  height: 20vh;
  background-color: #3f82cf;

}

  .miniBarMain {
    display: none;
  }

  .landD {
    display: none;
  }

  .allwrapLands {
    display: none;
  }

  .logoLoad {
    width: 18%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-bottom: 2%;
    animation: zoomInOut 4s infinite;

  }

  @keyframes zoomInOut {
    0% {
      transform: scale(1);
    }

    50% {
      transform: scale(1.1);
      /* Zoom in */
    }

    100% {
      transform: scale(1);
      /* Zoom out */
    }
  }

  .page-loader2 {
    width: 100%;
    height: 100vh;
    background: rgb(52, 52, 52);
    background: radial-gradient(circle, rgba(52, 52, 52, 1) 0%, rgba(22, 22, 22, 1) 50%, rgba(0, 0, 0, 1) 100%);
    position: absolute;
    z-index: 10000000;
    position: fixed;
    opacity: 0.5px;
  }

  .parent-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    flex-flow: column nowrap;
    /* Adjust the height based on your layout */
  }

  @keyframes l5 {

    0%,
    11% {
      background-position: 35% 35%
    }

    14%,
    36% {
      background-position: 65% 35%
    }

    38%,
    61% {
      background-position: 65% 65%
    }

    64%,
    86% {
      background-position: 35% 65%
    }

    88%,
    100% {
      background-position: 35% 35%
    }
  }

  /* src/App.css */
  .fade-container {
    background-color: gold;
    animation: fadeOut 2s forwards;
    /* Make sure the container covers the whole page */
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .fade-out {
    animation: fadeOut 2s forwards;
  }

  @keyframes fadeOut {
    from {
      opacity: 1;
    }

    to {
      opacity: 0;
      background-color: transparent;
    }
  }

  h1 {
    color: white;
    font-size: 2rem;
  }

  .light {
    height: 100vh;
    position: relative;
  }

  ::-webkit-scrollbar {
    display: none;
  }

  .mainBg {
    width: 100%;
    position: absolute;
  }

  .button {
    position: absolute;
    z-index: 1;
    margin-left: 47.8%;
    margin-top: 23.3%;
    background-color: rgba(133, 106, 72, 0);
    width: 9%;
    height: 35%;
    cursor: pointer;
  }


  .light2 {
    display: flex;
    justify-content: space-between;
    width: 40vw;
    margin-left: auto;
    margin-right: auto;
    background-color: aquamarine;

    width: 180px;
    height: 600px;
    overflow-y: scroll;
  }

  .land1 {
    width: 50%;
    height: 50%;
    width: 140px;
    height: 140px;
    margin: 10px;

    background-color: #a8a8df;
  }

  @keyframes float {

    0%,
    100% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-20px);
    }
  }

  .land2 {
    width: 50%;
    height: 50%;
  }

  .land3 {
    width: 50%;
    height: 50%;
  }

  .images3 {
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
  }

  .images3 img {
    width: 45%;
    margin-right: 5%;
    margin-left: 5%;
    position: relative;
  }

  .imgsMainDiv {
    position: relative;
    width: 100%;
    /* Ensure the container takes up the full width */
    height: 100vh;
    /* Ensure the container takes up the full viewport height */
    overflow-x: auto;
    overflow-y: hidden;
    /* Optionally hide vertical scrollbar */
    background: rgb(0, 0, 0);
    /*background: linear-gradient(180deg, rgba(81, 156, 0, 1) 0%, rgba(81, 156, 0, 1) 50%, rgba(52, 99, 0, 1) 100%)*/;
  }

  .bgImgs {
    position: absolute;
    top: 0;
    left: 0;
    width: 230%;
    /* Full viewport width */
    height: auto;
    /* Maintain aspect ratio */
  }

  .img1 {
    width: 31%;
    position: absolute;
    z-index: 5;
   margin-top: 9.8%; 
    margin-left: 22%;
    animation: floating 8s ease-in-out infinite;
  }

  .img2 {
    width: 55%;
    position: absolute;
    z-index: 5;
    margin-left: 163%;
    margin-top: 1%;
    animation: floating 8.2s ease-in-out infinite;
  }

  .img3 {
    width: 62%;
    position: absolute;
    z-index: 5;
    margin-left: 84%;
    margin-top: 4%;
    animation: floating 8s ease-in-out infinite;
  }

  @keyframes floating {
    0% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-10px);
    }

    100% {
      transform: translateY(0);
    }
  }

  .element1 {
    width: 8.8%;
    position: absolute;
    z-index: 84;
    margin-left: 6.5%;
    animation: floating 5s ease-in-out infinite;
  }

  .element2 {
    width: 6%;
    position: absolute;
    z-index: 84;
    margin-left: 62.5%;
    margin-top: 2%;
    animation: floating 10s ease-in-out infinite;
  }

  .element3 {
    width: 15%;
    position: absolute;
    z-index: 84;
    margin-left: 57%;
    margin-top: 10%;
    animation: floating 12s ease-in-out infinite;
  }

  .element4 {
    width: 6.5%;
    position: absolute;
    z-index: 84;
    margin-left: 80%;
    margin-top: 20%;
    animation: floating 13s ease-in-out infinite;
  }

  .element5 {
    width: 13%;
    position: absolute;
    z-index: 84;
    margin-left: 135%;
    margin-top: 18.2%;
    animation: floating 7s ease-in-out infinite;
  }

  .element6 {
    width: 15.5%;
    position: absolute;
    z-index: 4;
    margin-left: 166.8%;
    margin-top: 13.5%;
    animation: floating 7s ease-in-out infinite;
  }

  .element7 {
    width: 11%;
    position: absolute;
    z-index: 4;
    margin-left: 205.5%;
    margin-top: 5.5%;
    animation: floating 7s ease-in-out infinite;
  }

  .element8 {
    width: 5.5%;
    position: absolute;
    z-index: 100;
    margin-left: 210%;
    margin-top: 15.2%;
    animation: floating 10s ease-in-out infinite;
    cursor: pointer;
  }

  .element9 {
    width: 6.5%;
    position: absolute;
    z-index: 4;
    margin-left: 55.2%;
    margin-top: 32.5%;
    animation: floating 10s ease-in-out infinite;
  }

  .element10 {
    width: 6.5%;
    position: absolute;
    z-index: 5;
    margin-left: 46.5%;
    margin-top: 33.5%;
    animation: rotate-left-to-right 1s ease-in-out infinite alternate;
  }

  .element11 {
    width: 5%;
    position: absolute;
    z-index: 5;
    margin-left: 4%;
    margin-top: 30%;
    animation: rotate-left-to-right 1s ease-in-out infinite alternate;
  }


  @keyframes rotate-left-to-right {

    0% {
      transform: rotateZ(12deg);
    }

    100% {
      transform: rotateZ(8deg);
    }

  }

  /* In your CSS file */
  .goldenLight {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(255, 233, 110);
    opacity: 0;
    animation: emitLight 3s linear forwards;
    z-index: 9999;
    /* Ensure the golden light is above other content */
  }

  .page-loader {
    width: 100%;
    height: 100vh;
    background-color: rgb(255, 233, 110);
    position: absolute;
    z-index: 100;
    position: fixed;
    opacity: 0.5px;
  }


  @keyframes emitLight {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  .table {
    width: 100%;
    display: block;
    /* Ensure it's displayed as a block element */
    margin: 0;
    /* Remove default margin */
  }

  .bookElement {
    position: absolute;
    width: 52%;
    margin-left: 48%;
    margin-top: 25%;
    transform: rotate(-7deg);
    /* Initial rotation */
    cursor: pointer;
    transition: all 1s ease;
    /* Adding transition for all properties */
    padding: 0px;
    z-index: 5;
  }

  .bookElement.left {
    margin-left: 20%;
    /* Move to the left */
  }

  .bookElement.up {
    margin-top: 10%;
    /* Move up slightly */
    transform: rotate(0deg);
    /* Rotate to 30deg */
  }

  .bookElement.moving {
    margin-left: calc(42% - 26%);
    /* Adjustment based on the actual size of the book element */
    margin-top: 5%;
    transform: rotate(0deg);
    z-index: 2;
    width: 67%;
    position: fixed;

  }

  .backgroundOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    opacity: 0;
    transition: opacity 0.5s ease;
    z-index: 1;
  }


  .game {
    z-index: 1;
    position: absolute;
    width: 11%;
    margin-left: 40%;
    margin-top: 20%;
    rotate: 18deg;
  }

  /* Styles for the popup */
  .popup {
    transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 100000000000;
    background-color: rgba(0, 0, 0, 0.726);
  }

  .bookOpened {
    width: 85%;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .close {
    position: absolute;
    top: 90px;
    right: 100px;
    cursor: pointer;
    font-size: 35px;
    z-index: 50;
    color: white;
    transition: 0.2s ease-in-out;
  }

  .close:hover {
    color: rgb(156, 156, 156);
  }

  .allwrapAbout {
    background: url('./assets/7639148.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
  }


  .allwrapSocial {
    background-color: rgb(0, 0, 0);
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
  }

  .allwrapAbout2 {
    min-height: 100vh;
    height: max-content;
  }

  /* Initially hide the navDiv elements */
  .headers2 .right .navDiv {
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
  }

  /* Show navDiv elements when hovering over .headers2 or .logo */
  .headers2:hover .right .navDiv {
    opacity: 1;
  }

  .logo:hover~.right .navDiv {
    opacity: 1;
  }

  /* Styling for the logo image */
  .logoPic {
    width: 170px;
    /* Adjust width as needed */
    cursor: pointer;
  }

  /* Basic styling for .navDiv */
  .navDiv {
    margin-left: 35px;
    color: white;
    font-size: 22px;
    font-weight: 400;
    cursor: pointer;
    transition: color 0.2s ease-in-out;
    /* Add smooth color transition */
  }

  .navDiv:hover {
    color: #c5c4c4;
  }

  .headers {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10000;
    padding: 15px;
    padding-bottom: 15px;
    background: rgb(0, 0, 0);
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.7315301120448179) 0%, rgba(0, 0, 0, 0.5942752100840336) 50%, rgba(0, 0, 0, 0.397) 100%);
  }


  /* Additional styling for .headers2 */
  .headers2 {
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin: 10px auto;

  }

  .right {
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
  }

  .aboutSection {
    width: 80%;
    margin-top: 9%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .carouselContainer {
    margin-top: 9%;
  }


  .sec1Img {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .sec1Img img {
    width: 550px;
    height: 550px;
    background-color: rgb(188, 247, 207);
    border: 8px solid rgb(81, 253, 136);
    border-radius: 30px;
    rotate: 6deg;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .sec2Img {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .sec2Img img {
    width: 550px;
    height: 550px;
    background-color: rgb(188, 247, 207);
    border: 8px solid rgb(81, 253, 136);
    border-radius: 30px;
    rotate: -6deg;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .sec3Img {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .sec3Img img {
    width: 550px;
    height: 550px;
    background-color: rgb(188, 247, 207);
    border: 8px solid rgb(81, 253, 136);
    border-radius: 30px;
    rotate: 6deg;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .title {
    color: #ffffff;
    font-size: 75px;
    margin-bottom: 1%;
  }

  .aboutSection p {
    color: rgb(255, 255, 255);
    font-family: "Nunito", sans-serif;
    font-size: 24px;
  }

  .eachSections1 {
    display: flex;
    justify-content: space-evenly;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    width: 90%;
    background-color: #3f82cf;
    margin-left: auto;
    margin-right: auto;
    padding: 40px;
    border-radius: 70px;
    rotate: -2deg;
    border: 8px solid white;
    padding: 30px;
    margin-bottom: 10%;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px;
  }

  #t2 {
    text-align: right;
  }

  .eachSections2 {
    display: flex;
    justify-content: space-evenly;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    width: 90%;
    background-color: #ff6f2f;
    margin-left: auto;
    margin-right: auto;
    padding: 40px;
    border-radius: 70px;
    rotate: 2deg;
    border: 8px solid white;
    padding: 30px;
    margin-bottom: 10%;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px;
  }

  .eachSections3 {
    display: flex;
    justify-content: space-evenly;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    width: 90%;
    background-color: #805eff;
    margin-left: auto;
    margin-right: auto;
    padding: 40px;
    border-radius: 70px;
    rotate: -2deg;
    border: 8px solid white;
    padding: 30px;
    margin-bottom: 10%;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px;

  }

  .eachSections {
    margin-top: 5%;
  }

  .whatsapp {
    animation: floating 4s ease-in-out infinite;
    width: 70px;
    height: 70px;
    z-index: 100;
    cursor: pointer;
    transition: transform .2s;
  }

  .whatsapp:hover {
    transform: scale(1.5);
  }

  .whatsappDiv {
    position: fixed;
    bottom: 30px;
    /* Adjust as needed */
    right: 40px;
    /* Adjust as needed */
    z-index: 10000000;
  }

  .detailsEgg {
    flex: 0 0 100%;
    display: none;
  }

  .detailsEgg.active {
    display: block;
  }

  .arrow {
    cursor: pointer;
    width: 110px;
    height: 110px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .left {
    margin-right: -40px;
    z-index: 5;
  }

  .right-2 {
    margin-left: -40px;
  }

  @keyframes pop2 {
    0% {
      transform: scale(0);
    }

    100% {
      transform: scale(1);
    }
  }


  .detailsEggSub {
    display: flex;
    justify-content: space-between;
    background-color: #275a72;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 20px;

  }

  .detailsEggSub2 {
    display: flex;
    justify-content: space-between;
    background-color: #c0692f;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 20px;
  }

  .detailsEggSub3 {
    display: flex;
    justify-content: space-between;
    background-color: #3f3c50;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 20px;
  }

  .detailsEggSub4 {
    display: flex;
    justify-content: space-between;
    background-color: #cc3a46;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 20px;
  }

  .detailsEggSub5 {
    display: flex;
    justify-content: space-between;
    background-color: #c56300;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 20px;
  }

  .detailsEggSub6 {
    display: flex;
    justify-content: space-between;
    background-color: #39509c;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 20px;
  }

  .detailsEggSub7 {
    display: flex;
    justify-content: space-between;
    background-color: #ce4d3f;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 20px;
  }

  .detailsEggSub8 {
    display: flex;
    justify-content: space-between;
    background-color: #b65269;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 20px;
  }

  .detailsEggSub9 {
    display: flex;
    justify-content: space-between;
    background-color: #37772d;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 20px;
  }

  .detailsEggSub10 {
    display: flex;
    justify-content: space-between;
    background-color: #853394;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 20px;
  }


  .eggMain {
    width: 40%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    animation: pop2 0.7s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;

  }

  .eggT {
    font-size: 70px;
    color: white;
    margin-bottom: 10px;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
  }

  .eggP {
    font-family: "Nunito", sans-serif;
    width: 80%;
    color: white;
    background-color: #fbfbfc27;
    border-radius: 12px;
    padding: 30px;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.096), 0px -5px 35px rgba(255, 255, 255, 0.3);
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    font-size: 22px;
  }

  .contentsEgg {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .carouselContainer {
    display: flex;
  }

  .littleEggsMain {
    display: flex;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 3%;
  }

  .littleEggs {
    width: 400px;
    margin-top: -100px;
    z-index: 10;
  }

  .littleEggs img {
    width: 140px;
    height: 140px;
    display: flex;
    transition: transform .2s;
  }

  .littleEggs img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  .littleEggs div {
    font-size: 21px;
    color: rgb(0, 0, 0);
    text-align: center;
  }

  /*.............. FOOTER ..............*/

  .footer {
    margin-top: 9%;
    margin-left: 5%;
  }

  .footerHeader {
    color: rgb(255, 255, 255);
    font-size: 70px;
  }

  .footerP {
    color: rgb(167, 167, 167);
    font-size: 21px;
    width: 50%;
    margin-top: 20px;
    font-family: "Nunito", sans-serif;
  }

  .line {
    width: 50%;
    height: 1px;
    background-color: rgba(219, 219, 219, 0.24);
    margin-top: 15px;
  }

  .footerBox1 {
    width: 400px;
    height: 133px;
    border-radius: 5px;
    margin-top: 25px;
    background-image: url(./assets/email_before.jpg);
    background-size: cover;
    border: none;
  }

  .footerBox1:hover {
    background-image: url(./assets/email_after.jpg);
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    color: rgb(226, 226, 226);
    cursor: pointer;

  }

  .footerBox2 {
    width: 400px;
    height: 133px;
    border-radius: 5px;
    margin-top: 25px;
    background-image: url(./assets/instagram_before.jpg);
    background-size: cover;
    margin-left: 25px;
    border: none;
  }

  .footerBox2:hover {
    background-image: url(./assets/instagram_after.jpg);
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    color: rgb(226, 226, 226);
    cursor: pointer;

  }

  .footerBox3 {
    width: 400px;
    height: 133px;
    border-radius: 5px;
    margin-top: 25px;
    background-image: url(./assets/twitter_before.jpg);
    background-size: cover;
    border: none;
  }

  .footerBox3:hover {
    background-image: url(./assets/twitter_after.jpg);
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    color: rgb(226, 226, 226);
    cursor: pointer;

  }

  .footerBox4 {
    width: 400px;
    height: 133px;
    border-radius: 5px;
    margin-top: 25px;
    background-image: url(./assets/discord_before.jpg);
    background-size: cover;
    margin-left: 25px;
    border: none;
  }

  .footerBox4:hover {
    background-image: url(./assets/discord_after.jpg);
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    color: rgb(226, 226, 226);
    cursor: pointer;

  }

  .iconsSection1 {
    display: flex;
    flex-flow: row nowrap;
  }

  .iconsSection2 {
    display: flex;
    flex-flow: row nowrap;
  }

  .footerDetails {
    color: black;
    font-size: 25px;
    font-weight: bolder;
    width: 400px;
    height: 133px;
    text-align: center;
    padding-left: 75px;
    padding-top: 40px;


  }

  .footerDetails2 {
    color: rgb(75, 75, 75);
    font-size: 24px;

  }

  .footerDetails:hover {
    color: rgb(226, 226, 226);
  }

  .footerDetails p {

    font-size: 20px;
    color: rgb(161, 161, 161);
    margin-top: -3px;
    font-family: "Nunito", sans-serif;

  }

  .footerDetails2:hover {
    color: rgb(201, 201, 201);
  }

  .elementSocial {
    animation: floating 4s ease-in-out infinite;
    width: 21%;
    z-index: 100;
    cursor: pointer;
    transition: transform .2s;
  }

  .elementSocial {
    position: fixed;
    bottom: 31%;
    /* Adjust as needed */
    right: 15%;
    /* Adjust as needed */
    z-index: 10000000;
    animation: rotate-left-to-right 1s ease-in-out infinite alternate;
  }
}

@media screen and (min-width: 1921px) and (max-width: 3839px) {
  .miniBarMain {
    display: none;
  }
  iframe {
    width: 120%;
    height: 120%;
    margin-top: 1px;
   }
  
   .iframeDiv{
    margin: 0;
    padding: 0;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #3f82cf;
    
   }
  
  .sky{
    width: 100%;
    height: 20vh;
    background-color: #3f82cf;
  
  }
  .landD {
    display: none;
  }

  .allwrapLands {
    display: none;
  }

  .logoLoad {
    width: 18%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-bottom: 2%;
    animation: zoomInOut 4s infinite;

  }

  @keyframes zoomInOut {
    0% {
      transform: scale(1);
    }

    50% {
      transform: scale(1.1);
      /* Zoom in */
    }

    100% {
      transform: scale(1);
      /* Zoom out */
    }
  }

  .page-loader2 {
    width: 100%;
    height: 100vh;
    background: rgb(52, 52, 52);
    background: radial-gradient(circle, rgba(52, 52, 52, 1) 0%, rgba(22, 22, 22, 1) 50%, rgba(0, 0, 0, 1) 100%);
    position: absolute;
    z-index: 10000000;
    position: fixed;
    opacity: 0.5px;
  }

  .parent-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    flex-flow: column nowrap;
    /* Adjust the height based on your layout */
  }

  @keyframes l5 {

    0%,
    11% {
      background-position: 35% 35%
    }

    14%,
    36% {
      background-position: 65% 35%
    }

    38%,
    61% {
      background-position: 65% 65%
    }

    64%,
    86% {
      background-position: 35% 65%
    }

    88%,
    100% {
      background-position: 35% 35%
    }
  }

  /* src/App.css */
  .fade-container {
    background-color: gold;
    animation: fadeOut 2s forwards;
    /* Make sure the container covers the whole page */
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .fade-out {
    animation: fadeOut 2s forwards;
  }

  @keyframes fadeOut {
    from {
      opacity: 1;
    }

    to {
      opacity: 0;
      background-color: transparent;
    }
  }

  h1 {
    color: white;
    font-size: 2rem;
  }

  .light {
    height: 100vh;
    position: relative;
  }

  ::-webkit-scrollbar {
    display: none;
  }

  .mainBg {
    width: 100%;
    position: absolute;
  }

  .button {
    position: absolute;
    z-index: 1;
    margin-left: 47.8%;
    margin-top: 23.3%;
    background-color: rgba(133, 106, 72, 0);
    width: 9%;
    height: 35%;
    cursor: pointer;
  }


  .light2 {
    display: flex;
    justify-content: space-between;
    width: 40vw;
    margin-left: auto;
    margin-right: auto;
    background-color: aquamarine;

    width: 180px;
    height: 600px;
    overflow-y: scroll;
  }

  .land1 {
    width: 50%;
    height: 50%;
    width: 140px;
    height: 140px;
    margin: 10px;

    background-color: #a8a8df;
  }

  @keyframes float {

    0%,
    100% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-20px);
    }
  }

  .land2 {
    width: 50%;
    height: 50%;
  }

  .land3 {
    width: 50%;
    height: 50%;
  }

  .images3 {
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
  }

  .images3 img {
    width: 45%;
    margin-right: 5%;
    margin-left: 5%;
    position: relative;
  }

  .imgsMainDiv {
    position: relative;
    width: 100%;
    /* Ensure the container takes up the full width */
    height: 100vh;
    /* Ensure the container takes up the full viewport height */
    overflow-x: auto;
    overflow-y: hidden;
    /* Optionally hide vertical scrollbar */
    background: rgb(0, 0, 0);
    /*background: linear-gradient(180deg, rgba(81, 156, 0, 1) 0%, rgba(81, 156, 0, 1) 50%, rgba(52, 99, 0, 1) 100%)*/;
  }

  .bgImgs {
    position: absolute;
    top: 0;
    left: 0;
    width: 230%;
    /* Full viewport width */
    height: auto;
    /* Maintain aspect ratio */
  }

  .img1 {
    width: 31%;
    position: absolute;
    z-index: 5;
   margin-top: 9.8%;
    margin-left: 22%;
    animation: floating 8s ease-in-out infinite;
  }

  .img2 {
    width: 55%;
    position: absolute;
    z-index: 5;
    margin-left: 163%;
    margin-top: 1%;
    animation: floating 8.2s ease-in-out infinite;
  }

  .img3 {
    width: 62%;
    position: absolute;
    z-index: 5;
    margin-left: 84%;
    margin-top: 4%;
    animation: floating 8s ease-in-out infinite;
  }

  @keyframes floating {
    0% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-10px);
    }

    100% {
      transform: translateY(0);
    }
  }

  .element1 {
    width: 8.8%;
    position: absolute;
    z-index: 84;
    margin-left: 6.5%;
    animation: floating 5s ease-in-out infinite;
  }

  .element2 {
    width: 6%;
    position: absolute;
    z-index: 84;
    margin-left: 62.5%;
    margin-top: 2%;
    animation: floating 10s ease-in-out infinite;
  }

  .element3 {
    width: 15%;
    position: absolute;
    z-index: 84;
    margin-left: 57%;
    margin-top: 10%;
    animation: floating 12s ease-in-out infinite;
  }

  .element4 {
    width: 6.5%;
    position: absolute;
    z-index: 84;
    margin-left: 80%;
    margin-top: 20%;
    animation: floating 13s ease-in-out infinite;
  }

  .element5 {
    width: 13%;
    position: absolute;
    z-index: 84;
    margin-left: 135%;
    margin-top: 18.2%;
    animation: floating 7s ease-in-out infinite;
  }

  .element6 {
    width: 15.5%;
    position: absolute;
    z-index: 4;
    margin-left: 166.8%;
    margin-top: 13.5%;
    animation: floating 7s ease-in-out infinite;
  }

  .element7 {
    width: 11%;
    position: absolute;
    z-index: 4;
    margin-left: 205.5%;
    margin-top: 5.5%;
    animation: floating 7s ease-in-out infinite;
  }

  .element8 {
    width: 5.5%;
    position: absolute;
    z-index: 100;
    margin-left: 210%;
    margin-top: 15.2%;
    animation: floating 10s ease-in-out infinite;
    cursor: pointer;
  }

  .element9 {
    width: 6.5%;
    position: absolute;
    z-index: 4;
    margin-left: 55.2%;
    margin-top: 32.5%;
    animation: floating 10s ease-in-out infinite;
  }

  .element10 {
    width: 6.5%;
    position: absolute;
    z-index: 5;
    margin-left: 46.5%;
    margin-top: 33.5%;
    animation: rotate-left-to-right 1s ease-in-out infinite alternate;
  }

  .element11 {
    width: 5%;
    position: absolute;
    z-index: 5;
    margin-left: 4%;
    margin-top: 30%;
    animation: rotate-left-to-right 1s ease-in-out infinite alternate;
  }


  @keyframes rotate-left-to-right {

    0% {
      transform: rotateZ(12deg);
    }

    100% {
      transform: rotateZ(8deg);
    }

  }

  /* In your CSS file */
  .goldenLight {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(255, 233, 110);
    opacity: 0;
    animation: emitLight 3s linear forwards;
    z-index: 9999;
    /* Ensure the golden light is above other content */
  }

  .page-loader {
    width: 100%;
    height: 100vh;
    background-color: rgb(255, 233, 110);
    position: absolute;
    z-index: 100;
    position: fixed;
    opacity: 0.5px;
  }


  @keyframes emitLight {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  .table {
    width: 100%;
    display: block;
    /* Ensure it's displayed as a block element */
    margin: 0;
    /* Remove default margin */
  }

  .bookElement {
    position: absolute;
    width: 52%;
    margin-left: 48%;
    margin-top: 25%;
    transform: rotate(-7deg);
    /* Initial rotation */
    cursor: pointer;
    transition: all 1s ease;
    /* Adding transition for all properties */
    padding: 0px;
    z-index: 5;
  }

  .bookElement.left {
    margin-left: 20%;
    /* Move to the left */
  }

  .bookElement.up {
    margin-top: 10%;
    /* Move up slightly */
    transform: rotate(0deg);
    /* Rotate to 30deg */
  }

  .bookElement.moving {
    margin-left: calc(42% - 26%);
    /* Adjustment based on the actual size of the book element */
    margin-top: 5%;
    transform: rotate(0deg);
    z-index: 2;
    width: 67%;
    position: fixed;

  }

  .backgroundOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    opacity: 0;
    transition: opacity 0.5s ease;
    z-index: 1;
  }


  .game {
    z-index: 1;
    position: absolute;
    width: 11%;
    margin-left: 40%;
    margin-top: 20%;
    rotate: 18deg;
  }

  /* Styles for the popup */
  .popup {
    transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 100000000000;
    background-color: rgba(0, 0, 0, 0.726);
  }

  .bookOpened {
    width: 85%;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .close {
    position: absolute;
    top: 130px;
    right: 100px;
    cursor: pointer;
    font-size: 45px;
    z-index: 50;
    color: white;
    transition: 0.2s ease-in-out;
  }

  .close:hover {
    color: rgb(156, 156, 156);
  }

  .allwrapAbout {
    background: url('./assets/7639148.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
  }


  .allwrapSocial {
    background-color: rgb(0, 0, 0);
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
  }

  .allwrapAbout2 {
    min-height: 100vh;
    height: max-content;
  }

  /* Initially hide the navDiv elements */
  .headers2 .right .navDiv {
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
  }

  /* Show navDiv elements when hovering over .headers2 or .logo */
  .headers2:hover .right .navDiv {
    opacity: 1;
  }

  .logo:hover~.right .navDiv {
    opacity: 1;
  }

  /* Styling for the logo image */
  .logoPic {
    width: 230px;
    /* Adjust width as needed */
    cursor: pointer;
  }

  /* Basic styling for .navDiv */
  .navDiv {
    margin-left: 45px;
    color: white;
    font-size: 30px;
    font-weight: 400;
    cursor: pointer;
    transition: color 0.2s ease-in-out;
    /* Add smooth color transition */
  }

  .navDiv:hover {
    color: #c5c4c4;
  }

  .headers {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10000;
    padding-top: 15px;
    padding-bottom: 15px;
    background: rgb(0, 0, 0);
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.7315301120448179) 0%, rgba(0, 0, 0, 0.5942752100840336) 50%, rgba(0, 0, 0, 0.397) 100%);
  }


  /* Additional styling for .headers2 */
  .headers2 {
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin: 10px auto;

  }

  .right {
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
  }

  .aboutSection {
    width: 80%;
    margin-top: 9%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .carouselContainer {
    margin-top: 9%;
  }


  .sec1Img {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .sec1Img img {
    width: 650px;
    height: 650px;
    background-color: rgb(188, 247, 207);
    border: 8px solid rgb(81, 253, 136);
    border-radius: 30px;
    rotate: 6deg;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .sec2Img {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .sec2Img img {
    width: 650px;
    height: 650px;
    background-color: rgb(188, 247, 207);
    border: 8px solid rgb(81, 253, 136);
    border-radius: 30px;
    rotate: -6deg;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .sec3Img {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .sec3Img img {
    width: 650px;
    height: 650px;
    background-color: rgb(188, 247, 207);
    border: 8px solid rgb(81, 253, 136);
    border-radius: 30px;
    rotate: 6deg;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .title {
    color: #ffffff;
    font-size: 100px;
    margin-bottom: 1%;
  }

  .aboutSection p {
    color: rgb(255, 255, 255);
    font-family: "Nunito", sans-serif;
    font-size: 32px;
  }

  .eachSections1 {
    display: flex;
    justify-content: space-evenly;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    width: 90%;
    background-color: #3f82cf;
    margin-left: auto;
    margin-right: auto;
    padding: 60px;
    border-radius: 70px;
    rotate: -2deg;
    border: 12px solid white;
    margin-bottom: 10%;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px;
  }

  #t2 {
    text-align: right;
  }

  .eachSections2 {
    display: flex;
    justify-content: space-evenly;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    width: 90%;
    background-color: #ff6f2f;
    margin-left: auto;
    margin-right: auto;
    padding: 60px;
    border-radius: 70px;
    rotate: 2deg;
    border: 12px solid white;
    margin-bottom: 10%;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px;
  }

  .eachSections3 {
    display: flex;
    justify-content: space-evenly;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    width: 90%;
    background-color: #805eff;
    margin-left: auto;
    margin-right: auto;
    padding: 40px;
    border-radius: 70px;
    rotate: -2deg;
    border: 12px solid white;
    padding: 60px;
    margin-bottom: 10%;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px;

  }

  .eachSections {
    margin-top: 5%;
  }

  .whatsapp {
    animation: floating 4s ease-in-out infinite;
    width: 100px;
    height: 100px;
    z-index: 100;
    cursor: pointer;
    transition: transform .2s;
  }

  .whatsapp:hover {
    transform: scale(1.5);
  }

  .whatsappDiv {
    position: fixed;
    bottom: 40px;
    /* Adjust as needed */
    right: 50px;
    /* Adjust as needed */
    z-index: 10000000;
  }

  .detailsEgg {
    flex: 0 0 100%;
    display: none;
  }

  .detailsEgg.active {
    display: block;
  }

  .arrow {
    cursor: pointer;
    width: 130px;
    height: 130px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .left {
    margin-right: -40px;
    z-index: 5;
  }

  .right-2 {
    margin-left: -40px;
  }

  @keyframes pop2 {
    0% {
      transform: scale(0);
    }

    100% {
      transform: scale(1);
    }
  }


  .detailsEggSub {
    display: flex;
    justify-content: space-between;
    background-color: #275a72;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 20px;

  }

  .detailsEggSub2 {
    display: flex;
    justify-content: space-between;
    background-color: #c0692f;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 20px;
  }

  .detailsEggSub3 {
    display: flex;
    justify-content: space-between;
    background-color: #3f3c50;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 20px;
  }

  .detailsEggSub4 {
    display: flex;
    justify-content: space-between;
    background-color: #cc3a46;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 20px;
  }

  .detailsEggSub5 {
    display: flex;
    justify-content: space-between;
    background-color: #c56300;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 20px;
  }

  .detailsEggSub6 {
    display: flex;
    justify-content: space-between;
    background-color: #39509c;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 20px;
  }

  .detailsEggSub7 {
    display: flex;
    justify-content: space-between;
    background-color: #ce4d3f;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 20px;
  }

  .detailsEggSub8 {
    display: flex;
    justify-content: space-between;
    background-color: #b65269;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 20px;
  }

  .detailsEggSub9 {
    display: flex;
    justify-content: space-between;
    background-color: #37772d;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 20px;
  }

  .detailsEggSub10 {
    display: flex;
    justify-content: space-between;
    background-color: #853394;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 20px;
  }


  .eggMain {
    width: 40%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    animation: pop2 0.7s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;

  }

  .eggT {
    font-size: 100px;
    color: white;
    margin-bottom: 10px;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
  }

  .eggP {
    font-family: "Nunito", sans-serif;
    width: 80%;
    color: white;
    background-color: #fbfbfc27;
    border-radius: 15px;
    padding: 35px;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.096), 0px -5px 35px rgba(255, 255, 255, 0.3);
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    font-size: 32px;
  }

  .contentsEgg {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .carouselContainer {
    display: flex;
  }

  .littleEggsMain {
    display: flex;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 3%;
  }

  .littleEggs {
    width: 400px;
    margin-top: -135px;
    z-index: 10;
  }

  .littleEggs img {
    width: 190px;
    height: 190px;
    display: flex;
    transition: transform .2s;
  }

  .littleEggs img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  .littleEggs div {
    font-size: 28px;
    color: rgb(0, 0, 0);
    text-align: center;
  }

  /*.............. FOOTER ..............*/

  .footer {
    margin-top: 9%;
    margin-left: 5%;
  }

  .footerHeader {
    color: rgb(255, 255, 255);
    font-size: 100px;
  }

  .footerP {
    color: rgb(167, 167, 167);
    font-size: 28px;
    width: 50%;
    margin-top: 20px;
    font-family: "Nunito", sans-serif;
  }

  .line {
    width: 50%;
    height: 1px;
    background-color: rgba(219, 219, 219, 0.24);
    margin-top: 25px;
    margin-bottom: 25px;
  }

  .footerBox1 {
    width: 500px;
    height: 167px;
    border-radius: 8px;
    margin-top: 25px;
    background-image: url(./assets/email_before.jpg);
    background-size: cover;
    border: none;
  }

  .footerBox1:hover {
    background-image: url(./assets/email_after.jpg);
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    color: rgb(226, 226, 226);
    cursor: pointer;

  }

  .footerBox2 {
    width: 500px;
    height: 167px;
    border-radius: 8px;
    margin-top: 25px;
    background-image: url(./assets/instagram_before.jpg);
    background-size: cover;
    margin-left: 35px;
    border: none;
  }

  .footerBox2:hover {
    background-image: url(./assets/instagram_after.jpg);
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    color: rgb(226, 226, 226);
    cursor: pointer;

  }

  .footerBox3 {
    width: 500px;
    height: 167px;
    border-radius: 8px;
    margin-top: 45px;
    background-image: url(./assets/twitter_before.jpg);
    background-size: cover;
    border: none;
  }

  .footerBox3:hover {
    background-image: url(./assets/twitter_after.jpg);
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    color: rgb(226, 226, 226);
    cursor: pointer;

  }

  .footerBox4 {
    width: 500px;
    height: 167px;
    border-radius: 8px;
    margin-top: 45px;
    background-image: url(./assets/discord_before.jpg);
    background-size: cover;
    margin-left: 35px;
    border: none;
  }

  .footerBox4:hover {
    background-image: url(./assets/discord_after.jpg);
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    color: rgb(226, 226, 226);
    cursor: pointer;

  }

  .iconsSection1 {
    display: flex;
    flex-flow: row nowrap;
  }

  .iconsSection2 {
    display: flex;
    flex-flow: row nowrap;
  }

  .footerDetails {
    color: black;
    font-size: 32px;
    font-weight: bolder;
    width: 500px;
    height: 167px;
    text-align: center;
    padding-left: 130px;
    padding-top: 45px;


  }

  .footerDetails2 {
    color: rgb(75, 75, 75);
    font-size: 32px;

  }

  .footerDetails:hover {
    color: rgb(226, 226, 226);
  }

  .footerDetails p {

    font-size: 25px;
    color: rgb(161, 161, 161);
    margin-top: -3px;
    font-family: "Nunito", sans-serif;

  }

  .footerDetails2:hover {
    color: rgb(201, 201, 201);
  }

  .elementSocial {
    animation: floating 4s ease-in-out infinite;
    width: 21%;
    z-index: 100;
    cursor: pointer;
    transition: transform .2s;
  }

  .elementSocial {
    position: fixed;
    bottom: 31%;
    /* Adjust as needed */
    right: 15%;
    /* Adjust as needed */
    z-index: 10000000;
    animation: rotate-left-to-right 1s ease-in-out infinite alternate;
  }
}

@media screen and (min-width: 3840px) {
  .miniBarMain {
    display: none;
  }
  iframe {
    width: 120%;
    height: 120%;
    }
  
   .iframeDiv{
    margin: 0;
    padding: 0;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #3f82cf;
    
   }
  
  .sky{
    width: 100%;
    height: 25vh;
    background-color: #3f82cf;
  
  }
  .landD {
    display: none;
  }

  .allwrapLands {
    display: none;
  }

  .logoLoad {
    width: 18%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-bottom: 2%;
    animation: zoomInOut 4s infinite;

  }

  @keyframes zoomInOut {
    0% {
      transform: scale(1);
    }

    50% {
      transform: scale(1.1);
      /* Zoom in */
    }

    100% {
      transform: scale(1);
      /* Zoom out */
    }
  }

  .page-loader2 {
    width: 100%;
    height: 100vh;
    background: rgb(52, 52, 52);
    background: radial-gradient(circle, rgba(52, 52, 52, 1) 0%, rgba(22, 22, 22, 1) 50%, rgba(0, 0, 0, 1) 100%);
    position: absolute;
    z-index: 10000000;
    position: fixed;
    opacity: 0.5px;
  }

  .parent-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    flex-flow: column nowrap;
    /* Adjust the height based on your layout */
  }

  @keyframes l5 {

    0%,
    11% {
      background-position: 35% 35%
    }

    14%,
    36% {
      background-position: 65% 35%
    }

    38%,
    61% {
      background-position: 65% 65%
    }

    64%,
    86% {
      background-position: 35% 65%
    }

    88%,
    100% {
      background-position: 35% 35%
    }
  }

  /* src/App.css */
  .fade-container {
    background-color: gold;
    animation: fadeOut 2s forwards;
    /* Make sure the container covers the whole page */
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .fade-out {
    animation: fadeOut 2s forwards;
  }

  @keyframes fadeOut {
    from {
      opacity: 1;
    }

    to {
      opacity: 0;
      background-color: transparent;
    }
  }

  h1 {
    color: white;
    font-size: 2rem;
  }

  .light {
    height: 100vh;
    position: relative;
  }

  ::-webkit-scrollbar {
    display: none;
  }

  .mainBg {
    width: 100%;
    position: absolute;
  }

  .button {
    position: absolute;
    z-index: 1;
    margin-left: 47.8%;
    margin-top: 23.3%;
    background-color: rgba(133, 106, 72, 0);
    width: 9%;
    height: 35%;
    cursor: pointer;
  }


  .light2 {
    display: flex;
    justify-content: space-between;
    width: 40vw;
    margin-left: auto;
    margin-right: auto;
    background-color: aquamarine;

    width: 180px;
    height: 600px;
    overflow-y: scroll;
  }

  .land1 {
    width: 50%;
    height: 50%;
    width: 140px;
    height: 140px;
    margin: 10px;

    background-color: #a8a8df;
  }

  @keyframes float {

    0%,
    100% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-20px);
    }
  }

  .land2 {
    width: 50%;
    height: 50%;
  }

  .land3 {
    width: 50%;
    height: 50%;
  }

  .images3 {
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
  }

  .images3 img {
    width: 45%;
    margin-right: 5%;
    margin-left: 5%;
    position: relative;
  }

  .imgsMainDiv {
    position: relative;
    width: 100%;
    /* Ensure the container takes up the full width */
    height: 100vh;
    /* Ensure the container takes up the full viewport height */
    overflow-x: auto;
    overflow-y: hidden;
    /* Optionally hide vertical scrollbar */
    background: rgb(0, 0, 0);
    /*background: linear-gradient(180deg, rgba(81, 156, 0, 1) 0%, rgba(81, 156, 0, 1) 50%, rgba(52, 99, 0, 1) 100%)*/;
  }

  .bgImgs {
    position: absolute;
    top: 0;
    left: 0;
    width: 230%;
    /* Full viewport width */
    height: auto;
    /* Maintain aspect ratio */
  }

  .img1 {
    width: 31%;
    position: absolute;
    z-index: 5;
   margin-top: 9.8%;
    margin-left: 22%;
    animation: floating 8s ease-in-out infinite;
  }

  .img2 {
    width: 55%;
    position: absolute;
    z-index: 5;
    margin-left: 163%;
    margin-top: 1%;
    animation: floating 8.2s ease-in-out infinite;
  }

  .img3 {
    width: 62%;
    position: absolute;
    z-index: 5;
    margin-left: 84%;
    margin-top: 4%;
    animation: floating 8s ease-in-out infinite;
  }

  @keyframes floating {
    0% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-10px);
    }

    100% {
      transform: translateY(0);
    }
  }

  .element1 {
    width: 8.8%;
    position: absolute;
    z-index: 84;
    margin-left: 6.5%;
    animation: floating 5s ease-in-out infinite;
  }

  .element2 {
    width: 6%;
    position: absolute;
    z-index: 84;
    margin-left: 62.5%;
    margin-top: 2%;
    animation: floating 10s ease-in-out infinite;
  }

  .element3 {
    width: 15%;
    position: absolute;
    z-index: 84;
    margin-left: 57%;
    margin-top: 10%;
    animation: floating 12s ease-in-out infinite;
  }

  .element4 {
    width: 6.5%;
    position: absolute;
    z-index: 84;
    margin-left: 80%;
    margin-top: 20%;
    animation: floating 13s ease-in-out infinite;
  }

  .element5 {
    width: 13%;
    position: absolute;
    z-index: 84;
    margin-left: 135%;
    margin-top: 18.2%;
    animation: floating 7s ease-in-out infinite;
  }

  .element6 {
    width: 15.5%;
    position: absolute;
    z-index: 4;
    margin-left: 166.8%;
    margin-top: 13.5%;
    animation: floating 7s ease-in-out infinite;
  }

  .element7 {
    width: 11%;
    position: absolute;
    z-index: 4;
    margin-left: 205.5%;
    margin-top: 5.5%;
    animation: floating 7s ease-in-out infinite;
  }

  .element8 {
    width: 5.5%;
    position: absolute;
    z-index: 100;
    margin-left: 210%;
    margin-top: 15.2%;
    animation: floating 10s ease-in-out infinite;
    cursor: pointer;
  }

  .element9 {
    width: 6.5%;
    position: absolute;
    z-index: 4;
    margin-left: 55.2%;
    margin-top: 32.5%;
    animation: floating 10s ease-in-out infinite;
  }

  .element10 {
    width: 6.5%;
    position: absolute;
    z-index: 5;
    margin-left: 46.5%;
    margin-top: 33.5%;
    animation: rotate-left-to-right 1s ease-in-out infinite alternate;
  }

  .element11 {
    width: 5%;
    position: absolute;
    z-index: 5;
    margin-left: 4%;
    margin-top: 30%;
    animation: rotate-left-to-right 1s ease-in-out infinite alternate;
  }


  @keyframes rotate-left-to-right {

    0% {
      transform: rotateZ(12deg);
    }

    100% {
      transform: rotateZ(8deg);
    }

  }

  /* In your CSS file */
  .goldenLight {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(255, 233, 110);
    opacity: 0;
    animation: emitLight 3s linear forwards;
    z-index: 9999;
    /* Ensure the golden light is above other content */
  }

  .page-loader {
    width: 100%;
    height: 100vh;
    background-color: rgb(255, 233, 110);
    position: absolute;
    z-index: 100;
    position: fixed;
    opacity: 0.5px;
  }


  @keyframes emitLight {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  .table {
    width: 100%;
    display: block;
    /* Ensure it's displayed as a block element */
    margin: 0;
    /* Remove default margin */
  }

  .bookElement {
    position: absolute;
    width: 52%;
    margin-left: 48%;
    margin-top: 25%;
    transform: rotate(-7deg);
    /* Initial rotation */
    cursor: pointer;
    transition: all 1s ease;
    /* Adding transition for all properties */
    padding: 0px;
    z-index: 5;
  }

  .bookElement.left {
    margin-left: 20%;
    /* Move to the left */
  }

  .bookElement.up {
    margin-top: 10%;
    /* Move up slightly */
    transform: rotate(0deg);
    /* Rotate to 30deg */
  }

  .bookElement.moving {
    margin-left: calc(42% - 26%);
    /* Adjustment based on the actual size of the book element */
    margin-top: 5%;
    transform: rotate(0deg);
    z-index: 2;
    width: 67%;
    position: fixed;

  }

  .backgroundOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    opacity: 0;
    transition: opacity 0.5s ease;
    z-index: 1;
  }


  .game {
    z-index: 1;
    position: absolute;
    width: 11%;
    margin-left: 40%;
    margin-top: 20%;
    rotate: 18deg;
  }

  /* Styles for the popup */
  .popup {
    transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 100000000000;
    background-color: rgba(0, 0, 0, 0.726);
  }

  .bookOpened {
    width: 85%;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .close {
    position: absolute;
    top: 190px;
    right: 100px;
    cursor: pointer;
    font-size: 65px;
    z-index: 50;
    color: white;
    transition: 0.2s ease-in-out;
  }

  .close:hover {
    color: rgb(156, 156, 156);
  }

  .allwrapAbout {
    background: url('./assets/7639148.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
  }


  .allwrapSocial {
    background-color: rgb(0, 0, 0);
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
  }

  .allwrapAbout2 {
    min-height: 100vh;
    height: max-content;
  }

  /* Initially hide the navDiv elements */
  .headers2 .right .navDiv {
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
  }

  /* Show navDiv elements when hovering over .headers2 or .logo */
  .headers2:hover .right .navDiv {
    opacity: 1;
  }

  .logo:hover~.right .navDiv {
    opacity: 1;
  }

  /* Styling for the logo image */
  .logoPic {
    width: 350px;
    /* Adjust width as needed */
    cursor: pointer;
  }

  /* Basic styling for .navDiv */
  .navDiv {
    margin-left: 65px;
    color: white;
    font-size: 45px;
    font-weight: 400;
    cursor: pointer;
    transition: color 0.2s ease-in-out;
    /* Add smooth color transition */
  }

  .navDiv:hover {
    color: #c5c4c4;
  }

  .headers {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10000;
    background: rgb(0, 0, 0);
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.7315301120448179) 0%, rgba(0, 0, 0, 0.5942752100840336) 50%, rgba(0, 0, 0, 0.397) 100%);
    padding-top: 20px;
    padding-bottom: 20px;
  }


  /* Additional styling for .headers2 */
  .headers2 {
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin: 10px auto;

  }

  .right {
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
  }

  .aboutSection {
    width: 80%;
    margin-top: 9%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .carouselContainer {
    margin-top: 9%;
  }


  .sec1Img {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .sec1Img img {
    width: 1000px;
    height: 1000px;
    background-color: rgb(188, 247, 207);
    border: 15px solid rgb(81, 253, 136);
    border-radius: 60px;
    rotate: 6deg;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .sec2Img {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .sec2Img img {
    width: 1000px;
    height: 1000px;
    background-color: rgb(188, 247, 207);
    border: 15px solid rgb(81, 253, 136);
    border-radius: 60px;
    rotate: -6deg;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .sec3Img {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .sec3Img img {
    width: 1000px;
    height: 1000px;
    background-color: rgb(188, 247, 207);
    border: 15px solid rgb(81, 253, 136);
    border-radius: 60px;
    rotate: 6deg;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .title {
    color: #ffffff;
    font-size: 160px;
    margin-bottom: 1%;
  }

  .aboutSection p {
    color: rgb(255, 255, 255);
    font-family: "Nunito", sans-serif;
    font-size: 45px;
  }

  .eachSections1 {
    display: flex;
    justify-content: space-evenly;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    width: 90%;
    background-color: #3f82cf;
    margin-left: auto;
    margin-right: auto;
    padding: 60px;
    border-radius: 70px;
    rotate: -2deg;
    border: 16px solid white;
    margin-bottom: 10%;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px;
  }

  #t2 {
    text-align: right;
  }

  .eachSections2 {
    display: flex;
    justify-content: space-evenly;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    width: 90%;
    background-color: #ff6f2f;
    margin-left: auto;
    margin-right: auto;
    padding: 60px;
    border-radius: 70px;
    rotate: 2deg;
    border: 16px solid white;
    margin-bottom: 10%;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px;
  }

  .eachSections3 {
    display: flex;
    justify-content: space-evenly;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    width: 90%;
    background-color: #805eff;
    margin-left: auto;
    margin-right: auto;
    padding: 40px;
    border-radius: 70px;
    rotate: -2deg;
    border: 16px solid white;
    padding: 60px;
    margin-bottom: 10%;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px;

  }

  .eachSections {
    margin-top: 5%;
  }

  .whatsapp {
    animation: floating 4s ease-in-out infinite;
    width: 180px;
    height: 180px;
    z-index: 100;
    cursor: pointer;
    transition: transform .2s;
  }

  .whatsapp:hover {
    transform: scale(1.5);
  }

  .whatsappDiv {
    position: fixed;
    bottom: 80px;
    /* Adjust as needed */
    right: 100px;
    /* Adjust as needed */
    z-index: 10000000;
  }

  .detailsEgg {
    flex: 0 0 100%;
    display: none;
  }

  .detailsEgg.active {
    display: block;
  }

  .arrow {
    cursor: pointer;
    width: 130px;
    height: 130px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .left {
    margin-right: -40px;
    z-index: 5;
  }

  .right-2 {
    margin-left: -40px;
  }

  @keyframes pop2 {
    0% {
      transform: scale(0);
    }

    100% {
      transform: scale(1);
    }
  }


  .detailsEggSub {
    display: flex;
    justify-content: space-between;
    background-color: #275a72;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 50px;

  }

  .detailsEggSub2 {
    display: flex;
    justify-content: space-between;
    background-color: #c0692f;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 50px;
  }

  .detailsEggSub3 {
    display: flex;
    justify-content: space-between;
    background-color: #3f3c50;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 50px;
  }

  .detailsEggSub4 {
    display: flex;
    justify-content: space-between;
    background-color: #cc3a46;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 50px;
  }

  .detailsEggSub5 {
    display: flex;
    justify-content: space-between;
    background-color: #c56300;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 50px;
  }

  .detailsEggSub6 {
    display: flex;
    justify-content: space-between;
    background-color: #39509c;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 50px;
  }

  .detailsEggSub7 {
    display: flex;
    justify-content: space-between;
    background-color: #ce4d3f;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 50px;
  }

  .detailsEggSub8 {
    display: flex;
    justify-content: space-between;
    background-color: #b65269;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 50px;
  }

  .detailsEggSub9 {
    display: flex;
    justify-content: space-between;
    background-color: #37772d;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 50px;
  }

  .detailsEggSub10 {
    display: flex;
    justify-content: space-between;
    background-color: #853394;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 50px;
  }


  .eggMain {
    width: 100%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    animation: pop2 0.7s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;

  }

  .eggT {
    font-size: 150px;
    color: white;
    margin-bottom: 10px;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
  }

  .eggP {
    font-family: "Nunito", sans-serif;
    width: 80%;
    color: white;
    background-color: #fbfbfc27;
    border-radius: 15px;
    padding: 35px;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.096), 0px -5px 35px rgba(255, 255, 255, 0.3);
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    font-size: 45px;
  }

  .contentsEgg {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .carouselContainer {
    display: flex;
  }

  .littleEggsMain {
    display: flex;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 3%;
  }

  .littleEggs {
    width: 400px;
    margin-top: -135px;
    z-index: 10;
  }

  .littleEggs img {
    width: 270px;
    height: 270px;
    display: flex;
    transition: transform .2s;
  }

  .littleEggs img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  .littleEggs div {
    font-size: 28px;
    color: rgb(0, 0, 0);
    text-align: center;
  }

  /*.............. FOOTER ..............*/

  .footer {
    margin-top: 9%;
    margin-left: 5%;
  }

  .footerHeader {
    color: rgb(255, 255, 255);
    font-size: 140px;
  }

  .footerP {
    color: rgb(167, 167, 167);
    font-size: 40px;
    width: 50%;
    margin-top: 20px;
    font-family: "Nunito", sans-serif;
  }

  .line {
    width: 50%;
    height: 1px;
    background-color: rgba(219, 219, 219, 0.24);
    margin-top: 35px;
    margin-bottom: 35px;
  }

  .footerBox1 {
    width: 750px;
    height: 250px;
    border-radius: 15px;
    margin-top: 35px;
    background-image: url(./assets/email_before.jpg);
    background-size: cover;
    border: none;
  }

  .footerBox1:hover {
    background-image: url(./assets/email_after.jpg);
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    color: rgb(226, 226, 226);
    cursor: pointer;

  }

  .footerBox2 {
    width: 750px;
    height: 250px;
    border-radius: 15px;
    margin-top: 25px;
    background-image: url(./assets/instagram_before.jpg);
    background-size: cover;
    margin-left: 45px;
    border: none;
  }

  .footerBox2:hover {
    background-image: url(./assets/instagram_after.jpg);
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    color: rgb(226, 226, 226);
    cursor: pointer;

  }

  .footerBox3 {
    width: 750px;
    height: 250px;
    border-radius: 15px;
    margin-top: 45px;
    background-image: url(./assets/twitter_before.jpg);
    background-size: cover;
    border: none;
  }

  .footerBox3:hover {
    background-image: url(./assets/twitter_after.jpg);
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    color: rgb(226, 226, 226);
    cursor: pointer;

  }

  .footerBox4 {
    width: 750px;
    height: 250px;
    border-radius: 15px;
    margin-top: 45px;
    background-image: url(./assets/discord_before.jpg);
    background-size: cover;
    margin-left: 35px;
    border: none;
  }

  .footerBox4:hover {
    background-image: url(./assets/discord_after.jpg);
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    color: rgb(226, 226, 226);
    cursor: pointer;

  }

  .iconsSection1 {
    display: flex;
    flex-flow: row nowrap;
  }

  .iconsSection2 {
    display: flex;
    flex-flow: row nowrap;
  }

  .footerDetails {
    color: black;
    font-size: 45px;
    font-weight: bolder;
    width: 750px;
    height: 250px;
    text-align: center;
    padding-left: 130px;
    padding-top: 80px;


  }

  .footerDetails2 {
    color: rgb(75, 75, 75);
    font-size: 32px;

  }

  .footerDetails:hover {
    color: rgb(226, 226, 226);
  }

  .footerDetails p {

    font-size: 35px;
    color: rgb(161, 161, 161);
    margin-top: -3px;
    font-family: "Nunito", sans-serif;

  }

  .footerDetails2:hover {
    color: rgb(201, 201, 201);
  }

  .elementSocial {
    animation: floating 4s ease-in-out infinite;
    width: 21%;
    z-index: 100;
    cursor: pointer;
    transition: transform .2s;
  }

  .elementSocial {
    position: fixed;
    bottom: 41%;
    /* Adjust as needed */
    right: 15%;
    /* Adjust as needed */
    z-index: 10000000;
    animation: rotate-left-to-right 1s ease-in-out infinite alternate;
  }
}